import React from "react";
import { Box, Flex, Select } from "@chakra-ui/react";

export const LeftSideLabelSelect = ({
  label,
  data,
  onSelect,
  value,
  forPaymentDetails,
}) => {
  return (
    <Flex alignItems={"center"} gap="1.2rem">
      <Box w="7rem" fontSize={"sm"}>
        {label}
      </Box>
      <Box>
        <Select
          // placeholder="Select option"
          w={{ base: "15rem", md: "15rem", sm: "10rem" }}
          size={"sm"}
          onChange={(e) => onSelect(e.target.value)}
          // value={value}
          defaultValue={forPaymentDetails ? data[0].name : data[0].uuid} //
          // value={value}
        >
          {data?.map((data) => {
            return (
              <option
                value={forPaymentDetails ? data.name : data.uuid}
                key={data.uuid}
                selected={data.name === value ? "true" : ""}
              >
                {data.name}
              </option>
            );
          })}
        </Select>
      </Box>
    </Flex>
  );
};
