import axios from "axios";
import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery, useMutation } from "react-query";

export const useCurrencyType = () => {
  async function getCurrencyType() {
    const res = await authorizationAxios.get(`${BASE_URL}/api/currency/`);
    return res.data;
  }
  const {
    data: currencyTypeData,
    error: currencyTypeError,
    isError: currencyTypeIsError,
    isLoading: currencyTypeIsLoading,
  } = useQuery("getCurrencyType", getCurrencyType);

  return {
    currencyTypeData,
  };
};
