import axios from "axios";
import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery, useMutation } from "react-query";

export const useDeletePassengerImage = () => {
  async function deletePassengerImage(values) {
    const res = await authorizationAxios.delete(
      `${BASE_URL}/api/heli-partners/passengers/`,
      {
        data: {
          uuid: values,
        },
      }
    );
    return res;
  }

  const deletePassengerImageMutation = useMutation(deletePassengerImage);

  return { deletePassengerImageMutation };
};
