import React from "react";
import { RecordFlights } from "../Record Flights/recordFlights";

export const EditReports = () => {
  return (
    <div>
      <RecordFlights editReports={true} />
    </div>
  );
};
