// import
import React, { Component } from "react";

import Reports from "views/Pages/Reports/Reports";

import { HomeIcon, StatsIcon, CreditIcon } from "components/Icons/Icons";
import { RecordFlights } from "views/Pages/Record Flights/recordFlights";
import { FareDetails } from "views/Pages/Fare Details/FareDetails";
import { EditReports } from "views/Pages/Reports/EditReports";
import { ViewRecord } from "views/Pages/Reports/viewRecord";

var dashRoutes = [
  {
    path: "/recordFlights",
    name: "Record Flights",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color="inherit" />,
    component: RecordFlights,
    layout: "/admin",
  },
  // {
  //   path: "/fareDetails",
  //   name: "Fare Details",
  //   rtlName: "لوحة القيادة",
  //   icon: <CreditIcon color="inherit" />,
  //   component: FareDetails,
  //   layout: "/admin",
  // },
  {
    path: "/reports",
    name: "Transactions",
    rtlName: "لوحة القيادة",
    icon: <CreditIcon color="inherit" />,
    component: Reports,
    layout: "/admin",
  },
  {
    path: "/editReport",
    name: "Edit Flights",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color="inherit" />,
    component: EditReports,
    layout: "/admin",
  },
  {
    path: "/viewRecord",
    name: "Flight Details",
    rtlName: "لوحة القيادة",
    icon: <StatsIcon color="inherit" />,
    component: ViewRecord,
    layout: "/admin",
  },
];
export default dashRoutes;
