/*!

=========================================================
* Argon Dashboard Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-chakra
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-chakra/blob/master/LICENSE.md)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import RTLLayout from "layouts/RTL.js"; // Chakra imports
import { ChakraProvider } from "@chakra-ui/react";
// Custom Chakra theme
import theme from "theme/theme.js";
import store from "redux/store";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import SignIn from "views/Pages/signin/SignIn";
import "app.css";
import RequireAuth from "components/Common/RequireAuth";
import PrivateRoute from "components/Common/PrivateRoute";
import persistStore from "redux-persist/es/persistStore";
import { PersistGate } from "redux-persist/integration/react";

const queryClient = new QueryClient();
let persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    {" "}
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />{" "}
      <ChakraProvider theme={theme} resetCss={false} position="relative">
        <PersistGate loading={null} persistor={persistor}>
          {" "}
          <HashRouter>
            <Switch>
              {/* <Route path={`/auth`} component={AuthLayout} /> */}
              <PrivateRoute path="/admin">
                <AdminLayout />
              </PrivateRoute>

              {/* <Route
              path="/admin"
              element={
                <RequireAuth>
                  <AdminLayout />
                </RequireAuth>
              }
            /> */}
              {/* <Route path={`/admin`} component={AdminLayout} /> */}
              {/* <Route path={`/rtl`} component={RTLLayout} /> */}
              <Route path={`/signin`} component={SignIn} />
              {/* <Redirect from={`/`} component={SignIn} /> */}
            </Switch>
          </HashRouter>
        </PersistGate>
      </ChakraProvider>
    </QueryClientProvider>{" "}
  </Provider>,
  document.getElementById("root")
);
