import { Box, Flex, VStack, Image } from "@chakra-ui/react";
import React from "react";

export const Profile = ({data}) => {
  return (
    <Flex gap="1rem" alignItems={"center"} justifyItems="center">
      <Image
        src={data?.profile}
        boxSize={"50px"}
        borderRadius="full"
        objectFit="cover"
        border={"1px solid orange"}
      />
      <Box>
        <Box fontSize={"md"}>{data?.first_name?(data?.first_name + " " + data?.last_name):'loading'}</Box>
        <Box fontSize={"xs"} color="gray.400">
          {data?.email?data?.email:"loading..."}
        </Box>
      </Box>
    </Flex>
  );
};
