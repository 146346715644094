import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const signInSlice = createSlice({
  name: "productListSlice",
  initialState: { loading: false },

  reducers: {
    signIn: (state, { payload }) => {
      // state.checkOutInfoPersist = payload;
      state.signIN = payload;
    },
  },
});

export const signInReducer = signInSlice.reducer;
export const { signIn } = signInSlice.actions;
