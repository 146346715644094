import React from "react";
import { useState } from "react";

export const useCargoDetails = (onOpen, editReports, infoForEdit) => {
  let unit = [
    { system: "kg", uuid: "kg", name: "kg" },
    { system: "mins", uuid: "mins", name: "mins" },
  ];
  const [file, setFile] = React.useState([]);
  const [value, setValue] = useState(editReports ? infoForEdit?.total_kgs : ""); //weight value
  const [perKgPrice, setPerKgPrice] = useState(
    editReports ? infoForEdit?.kg_price : ""
  );

  const [isSpecifiedWeight, setIsSpecifiedWeight] = useState(false);
  const [weightUnit, setWeightUnit] = useState(
    editReports ? infoForEdit?.value_unit : unit[0].uuid
  ); //weight unit

  const openModalWithSpecifiedWeight = () => {
    setIsSpecifiedWeight(true);
    onOpen();
  };
  return {
    file,
    setFile,
    value,
    setValue,
    openModalWithSpecifiedWeight,
    unit,
    weightUnit,
    setWeightUnit,
    isSpecifiedWeight,
    perKgPrice,
    setPerKgPrice,
  };
};
