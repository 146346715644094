import React from "react";
// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Input,
  useColorModeValue,
  Image,
  Center,
} from "@chakra-ui/react";


import { useSignIn } from "./useSignIn";
import { PageTitle } from "components/Common/PageTitle";

function SignIn() {
  // Chakra color mode
  const bgForm = useColorModeValue("white", "orange.700");

  const { formik } = useSignIn();
  return (
    <Box mt={"150px"}>
          <Flex
            zIndex="2"
            direction="column"
            w={{base:"445px",md:"445px",sm:'90vw'}}
            background="transparent"
            borderRadius="15px"
            p="40px"
            m={{ base: "20px", md: "auto" }}
            bg={bgForm}
            boxShadow={useColorModeValue(
              "0px 5px 14px rgba(0, 0, 0, 0.05)",
              "unset"
            )}
          >
            <form onSubmit={formik.handleSubmit}>
              <Center>
                <Image src={process.env.PUBLIC_URL+'/favicon.png'} height="80px" mb={4}/>
              </Center>
              <Center mb={4}>
                <PageTitle title="ELITE PARTNERS PORTAL" />
              </Center>

              <FormControl>
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Email
                </FormLabel>
                <Input
                  id="email"
                  variant="auth"
                  fontSize="sm"
                  ms="4px"
                  type="text"
                  placeholder="Your Email"
                  mb="24px"
                  size="lg"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Password
                </FormLabel>
                <Input
                  id="password"
                  variant="auth"
                  fontSize="sm"
                  ms="4px"
                  type="password"
                  placeholder="Your password"
                  mb="24px"
                  size="lg"
                  onChange={formik.handleChange}
                  value={formik.values.password}
                />

                <Button
                  fontWeight="bold"
                  w="100%"
                  h="45"
                  mb="24px"
                  bg={"orange.700"}
                  color="white"
                  _hover={{background:"orange"}}
                  type="submit"
                >
                  SIGN IN
                </Button>
              </FormControl>
            </form>
          </Flex>
    </Box>
  );
}

export default SignIn;
