const { Redirect, Route } = require("react-router-dom");

function PrivateRoute({ children, ...rest }) {
  const authed = localStorage.getItem("access_token");
  return (
    <Route
      {...rest}
      render={() => {
        return authed ? children : <Redirect to="/signin" />;
      }}
    />
  );
}

export default PrivateRoute;
