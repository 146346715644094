import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";

export const getSpecificTransactionList = createAsyncThunk(
  "api/getSpecificTransactionList",
  async (uuid, thunkAPI) => {
    const res = await authorizationAxios.get(
      `${BASE_URL}/api/heli-partners/transaction-list/?uuid=${uuid}}`
    );

    return res.data.data;
  }
);

export const editFlightSlice = createSlice({
  name: "info for edit flight",
  initialState: {},

  reducers: {
    infoForEditFlight: (state, { payload }) => {
      // state.checkOutInfoPersist = payload;

      state.info = payload;
    },
  },
  extraReducers: {
    [getSpecificTransactionList.fulfilled]: (state, { payload }) => {
      // state.info = dataGetTransactionList?.data?.flight[0];
      state.info = payload.flight[0];
    },
  },
});

export const editFlightReducer = editFlightSlice.reducer;
export const { infoForEditFlight } = editFlightSlice.actions;
