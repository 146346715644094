import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useMutation } from "react-query";

export const useRecordFlight = () => {
  async function postRecord(values) {
    const res = await authorizationAxios.post(
      `${BASE_URL}/api/heli-partners/flight-record/`,
      values
    );
    return res.data;
  }
  const postRecordMutation = useMutation(postRecord);

  return {
    postRecordMutation,
  };
};
