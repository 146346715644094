import React from "react";
import { Flex, Box, Input } from "@chakra-ui/react";

export const LeftSideLabelInput = ({ label, type, onChange, value }) => {
  return (
    <Flex gap="1.2rem" alignItems="center">
      <Box w="7rem" fontSize={"sm"}>
        {label}
      </Box>
      <Box>
        <Input
          type={type}
          w={{base:"15rem",md:"15rem",sm:"10rem"}}
          onChange={(e) => onChange(e.target.value)}
          value={value}
        />
      </Box>
    </Flex>
  );
};
