import React from "react";
import { GrSort } from "react-icons/gr";
import {
  Flex,
  Grid,
  GridItem,
  useColorMode,
  Image,
  Box,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Button,
  IconButton,
} from "@chakra-ui/react";
import { useGetStatusList } from "../API/useGetStatusList";

export const ReportSorting = ({ setUuid }) => {
  const { dataGetStatusList } = useGetStatusList();
  // console.log("getStatusList", getStatusList);
  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton isActive={isOpen} as={IconButton} icon={<GrSort />} />
          {/* <Box w="1.5rem" objectFit={"cover"}>
                      {" "}
                      <Image src="/filter-2.png" />
                    </Box> */}

          <MenuList>
            {dataGetStatusList?.map((list) => {
              return (
                <MenuItem key={list.uuid} onClick={() => setUuid(list.uuid)}>
                  {list.name}
                </MenuItem>
              );
            })}
            <MenuItem onClick={() => setUuid(false)}>View all</MenuItem>
          </MenuList>
        </>
      )}
    </Menu>
  );
};
