import React from "react";
import { useState } from "react";

export const usePaymentDetails = (
  editReports,
  infoForEdit,
  currencyTypeData
) => {
  const [invoiceImage, setInvoiceImage] = useState(null);
  const [paymentFile, setPaymentFile] = React.useState([]);
  const [currencyUnit, setCurrencyUnit] = React.useState(
    editReports ? infoForEdit?.invoice.unit : currencyTypeData?.data[0].name
  );
  const [amount, setAmount] = useState(
    editReports ? infoForEdit?.invoice.invoice_amount : ""
  );

  // data transform for payment
  const getPayment = (values) => {
    // console.log("valuesss", values.paymentName);
    // let totalAmount = 0;
    // values.paymentAmount.forEach((amount) => {
    //   totalAmount = +amount + totalAmount;
    // });
    // // console.log("totalAmount", totalAmount);
    // setAmount(totalAmount);
    // values.paymentName.map((_, index) => {
    //   setPayment((oldArray) => [
    //     ...oldArray,
    //     {
    //       name: values.paymentName[index],
    //       amount: values.paymentAmount[index],
    //     },
    //   ]);
    // });
  };
  return {
    invoiceImage,
    setInvoiceImage,
    paymentFile,
    setPaymentFile,
    currencyUnit,
    setCurrencyUnit,
    amount,
    setAmount,
    getPayment,
  };
};
