import React from "react";
import {
  Flex,
  Grid,
  GridItem,
  Text,
  Box,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Textarea,
  useDisclosure,
  useToast,
  Spinner,
  Button,
  Select,
} from "@chakra-ui/react";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { LeftSideLabelSelect } from "components/Common/LeftSideLabelSelect";
import { SaveButton } from "components/Common/SaveButton";
import { CancelButton } from "components/Common/CancelButton";
import Card from "components/Card/Card";
import { UploadUI } from "components/Common/UploadUI";
import { LocationModal } from "./Components/LocationModal";
import { useTypeOfTrip } from "./API/useTypeOfTrip";
import { useGetFlightNumber } from "./API/useGetFliteNumber";
import { PaymentDetails } from "./Components/PaymentDetails";
import { FlightDetails } from "./Components/FlightDetails";
import { useState } from "react";
import { IoArrowForward } from "react-icons/io5";
import { useRecordFlight } from "./API/useRecordFlight";
import { useEffect } from "react";
import axios from "axios";
import { PassengerDetails } from "./Components/PassengerDetails";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "constants/BASE_URL";
import { values } from "lodash";
import { useCurrencyType } from "./API/useCurrencyType";
import { CargoDetails } from "./Components/CargoDetails";
import { useCargoDetails } from "./Components/useCargoDetails";
import { usePaymentDetails } from "./Components/usePaymentDetails";
import { usePostInvoiceExtra } from "./API/usePostInvoiceExtra";
import { usePostTripLocation } from "./API/usePostTripLocation";
import { usePostAddPassenger } from "./API/usePostAddPassenger";
import { usePutImageInBulkCargoDetails } from "./API/usePutImageInBulkCargoDetails";
import { useHistory } from "react-router-dom";
import { getSpecificTransactionList } from "../Reports/slice/editFlightSlice";
import { EditLocationModel } from "../Reports/Components/EditLocationModel";
import { EditPassengerDetails } from "../Reports/Components/EditPassengerDetails";
import moment from "moment/moment";

export const RecordFlights = ({ editReports }) => {
  const { postInvoiceExtraMutation } = usePostInvoiceExtra();
  const { postTripLocationMutation } = usePostTripLocation();
  const { postAddPassengerMutation } = usePostAddPassenger();
  const {
    putImageInBulkCargoDetailsMutation,
  } = usePutImageInBulkCargoDetails();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { dataTypeOfTrip } = useTypeOfTrip();
  const { dataGetFlightList } = useGetFlightNumber();
  const { currencyTypeData } = useCurrencyType();

  const { postRecordMutation } = useRecordFlight();
  const { isLoading, isError, data, error, isSuccess } = postRecordMutation;
  const [isPassenger, setIsPassenger] = useState(true);
  const toast = useToast();
  const [loading, setLoading] = useState(false);

  const infoForEdit = useSelector((state) => state.infoForEditFlight.info);
  const [totalMinute, setTotalMinute] = useState(
    editReports ? infoForEdit?.total_minutes : ""
  );
  const [perMinutePrice, setPerMinutePrice] = useState(
    editReports ? infoForEdit?.minute_price : ""
  );

  // data collection for api hit
  const [location, setLocation] = useState(
    editReports ? infoForEdit?.location : []
  );

  const [flight, setFlight] = useState(""); //flight type

  const {
    file,
    setFile,
    value,
    setValue,
    openModalWithSpecifiedWeight,
    unit,
    weightUnit,
    setWeightUnit,
    isSpecifiedWeight,
    perKgPrice,
    setPerKgPrice,
  } = useCargoDetails(onOpen, editReports, infoForEdit);

  const {
    invoiceImage,
    setInvoiceImage,
    paymentFile,
    setPaymentFile,
    currencyUnit,
    setCurrencyUnit,
    amount,
    setAmount,
    getPayment,
  } = usePaymentDetails(editReports, infoForEdit, currencyTypeData);
  useEffect(() => {
    if (dataTypeOfTrip) {
      setFlight(
        editReports
          ? `{"name":"${infoForEdit?.type_of_flight?.name}","description":"${infoForEdit?.type_of_flight?.description}","uuid":"${infoForEdit?.type_of_flight?.uuid}"}`
          : `{"name":"${dataTypeOfTrip[0]?.name}","description":"${dataTypeOfTrip[0]?.description}","uuid":"${dataTypeOfTrip[0]?.uuid}"}`
      );
    }
  }, [dataTypeOfTrip]);

  const [flightNum, setFlightNum] = useState(
    editReports ? infoForEdit?.flight_number : ""
  ); //flight num
  const [pilotName, setPilotName] = useState(
    editReports ? infoForEdit?.pilot_name : ""
  ); //Pilot Name
  const [note, setNote] = useState(editReports ? infoForEdit?.description : ""); //Pilot Name
  const [cargoImage, setCargoImage] = useState(null);

  // passenger data
  const [passengerData, setPassengerData] = useState(
    editReports
      ? infoForEdit?.passenger.filter((item) => item.name !== null)
      : []
  );

  const [passenger, setPassenger] = useState(
    editReports
      ? infoForEdit?.passenger.filter((item) => item.name !== null)
      : [{ name: "" }]
  );

  const [passengerImage, setPassengerImage] = useState(null);
  const [isFieldEmpty, setIsFieldEmpty] = useState(false);

  const [passengerFile, setPassengerFile] = React.useState([]);
  const [addedTransitTracker, setAddedTransitTracker] = React.useState([]);
  const [payment, setPayment] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();

  let transitLocationArray = infoForEdit?.location ? infoForEdit?.location : [];
  let onlyTransitLocationArray = [...transitLocationArray].slice(
    1,
    transitLocationArray.length - 1
  );

  console.log("transitLocationArray", onlyTransitLocationArray);
  const [arrayTest, setArrayTest] = useState(
    editReports ? [...onlyTransitLocationArray] : []
  ); // used for adding transit location onClick event

  const passengerNameList = passengerData.map((list) => {
    return { name: list.name };
  });

  const passengerImageList = passengerData.map((list) => {
    return { image: list.image };
  });

  let startLocationDate = infoForEdit?.location[0]?.date.split("T")[0];
  let startLocationTime = infoForEdit?.location[0]?.date
    .split("T")[1]
    .split("+")[0];
  let DestinationLocationDate = infoForEdit?.location[
    infoForEdit?.location.length - 1
  ]?.date.split("T")[0];

  let DestinationLocationTime = infoForEdit?.location[
    infoForEdit?.location.length - 1
  ]?.date
    .split("T")[1]
    .split("+")[0];
  // console.log("startLocationDate", DestinationLocationDate);

  const currentTime = moment(new Date()).format("HH:mm");
  console.log("Date", currentTime);
  const formik = useFormik({
    initialValues: {
      addLocationStartingLocation: editReports
        ? infoForEdit?.location[0]?.name
        : "",
      addLocationStartDate: editReports ? startLocationDate : "",
      addLocationStartTime: editReports
        ? startLocationTime === "00:00:00"
          ? ""
          : startLocationTime
        : currentTime,
      addLocationStartWeight: 0,
      addLocationDestinationLocation: editReports
        ? infoForEdit?.location[infoForEdit?.location.length - 1]?.name
        : "",
      addLocationDestinationDate: editReports ? DestinationLocationDate : "",
      addLocationDestinationTime: editReports
        ? DestinationLocationTime === "00:00:00"
          ? ""
          : DestinationLocationTime
        : currentTime,
      addLocationDestinationWeight: 0,
      addLocationTransitLocations: editReports
        ? onlyTransitLocationArray.map((list) => list.name)
        : [],
      addLocationTransitDate: editReports
        ? onlyTransitLocationArray.map((list) => list.date.split("T")[0])
        : [],
      addLocationTransitTime: editReports
        ? onlyTransitLocationArray.map(
            (list) => list.date.split("T")[1].split("+")[0]
          )
        : [],
      paymentName: editReports
        ? infoForEdit?.invoice_extra.map((list) => list.name)
        : ["Flight Fair"],
      paymentAmount: editReports
        ? infoForEdit?.invoice_extra.map((list) => list.amount)
        : [],
    },
    enableReinitialize: false,
    onSubmit: (values) => {
      if (
        !values.addLocationStartingLocation ||
        !values.addLocationStartDate ||
        // !values.addLocationStartTime ||
        !values.addLocationDestinationLocation ||
        !values.addLocationDestinationDate
        // !values.addLocationDestinationTime
      ) {
        toast({
          title: "Some Location field are empty!",
          position: "top-right",
          // description: "You will shortly contact you.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } else {
        getLocations(values);
      }
      getPayment(values);
    },
  });

  async function handleSubmit() {
    formik.handleSubmit();

    if (flight === "") {
      toast({
        title: "Please select, type of trip!",
        position: "top-right",
        // description: "You will shortly contact you.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    if (location.length === 0) {
      toast({
        title: "Location is not selected!",
        position: "top-right",
        // description: "You will shortly contact you.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    if (!value && !isPassenger) {
      toast({
        title: "Please select,unit!",
        position: "top-right",
        // description: "You will shortly contact you.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    if (isPassenger && passengerNameList[0]?.name === "") {
      setIsFieldEmpty(true);
      toast({
        title: "Please select,passenger name",
        position: "top-right",
        // description: "You will shortly contact you.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    if (!formik.values.paymentAmount[0]) {
      setIsFieldEmpty(true);
      toast({
        title: "Please enter the, payment amount",
        position: "top-right",
        // description: "You will shortly contact you.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }

    if (isPassenger && passengerData.length === 0) {
      setIsFieldEmpty(true);
      toast({
        title: "Please select,passenger name",
        position: "top-right",
        // description: "You will shortly contact you.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    console.log("valuesss for put", {
      value: value,
      value_unit: weightUnit,
      pilotName,
      flightNum,
      flight: JSON.parse(flight).uuid,
      note,
      location,
      payment,
      amount,
      currencyUnit,
      passenger,
    });

    console.log("passengerImage", passengerFile);

    if (isPassenger) {
      setLoading(true);
      const formData = new FormData();
      formData.append("type_of_flight", JSON.parse(flight).uuid);
      // formData.append("value", null);
      // formData.append("value_unit", null);
      formData.append("pilot_name", pilotName);
      formData.append("flight_number", flightNum ? flightNum : null);
      formData.append("description", note);
      formData.append("total_minutes", totalMinute);
      formData.append("total_kgs", null);
      formData.append("minute_price", perMinutePrice);
      formData.append("kg_price", null);

      if (editReports) {
        formData.append(
          "location",
          JSON.stringify(
            infoForEdit?.location
              .map((list, index) => {
                return {
                  uuid: list.uuid,
                  name: location[index]?.name,
                  date: location[index]?.date,
                  weight: location[index]?.weight,
                };
              })
              .filter((list) => list.name)
          )
        );

        formData.append("uuid", infoForEdit?.uuid);
        formData.append(
          "invoice",
          JSON.stringify({
            uuid: infoForEdit?.invoice.uuid,
            invoice_date: new Date().toISOString().slice(0, 10),
            due_date: new Date().toISOString().slice(0, 10),
            total_amount: amount,
            tax: 0,
            discount: 0,
            invoice_amount: amount,
            device_id: "web",
            unit: currencyUnit,
            is_paid:  infoForEdit?.invoice.is_paid?"True":"False"
          })
        );
        formData.append(
          "invoice_extra",
          JSON.stringify(
            infoForEdit?.invoice_extra.map((list, index) => {
              return {
                uuid: list.uuid,
                name: payment[index].name,
                amount: payment[index].amount,
              };
            })
          )
        );
        console.log("passengerNameList", passengerNameList);
        formData.append(
          "passenger",
          JSON.stringify(
            infoForEdit?.passenger
              .map((list, index) => {
                return {
                  uuid: list.uuid,
                  name: passengerNameList[index]?.name,
                };
              })
              .filter((list) => {
                return list.name;
              })
          )
        );
      } else {
        formData.append("location", JSON.stringify(location));
        formData.append("invoice_extra", JSON.stringify(payment));

        formData.append(
          "invoice",
          JSON.stringify({
            invoice_date: new Date().toISOString().slice(0, 10),
            due_date: new Date().toISOString().slice(0, 10),
            total_amount: amount,
            tax: 0,
            discount: 0,
            invoice_amount: amount,
            device_id: "web",
            unit: currencyUnit,
            is_paid: "False"
          })
        );
      }
      // cargoImage?.map((item) => formData.append("image", item));
      if (invoiceImage) {
        formData.append("invoice_image", invoiceImage);
      }

      try {
        let response;
        if (editReports) {
          const extraLocatonField = addedTransitTracker
            .map((list, index) => {
              if (infoForEdit.location[index]?.uuid) {
                return;
              } else {
                return {
                  name: list.name,
                  date:
                    list.date.split("T")[1] === "undefined"
                      ? list.date.split("T")[0]
                      : list.date,
                  weight: list.weight,
                  record: infoForEdit?.uuid,
                };
              }
            })
            .filter((list) => list !== undefined);
          if (extraLocatonField.length >= 1) {
            postTripLocationMutation.mutate(extraLocatonField);
          }

          const extraPaymentField = payment
            .map((list, index) => {
              if (infoForEdit.invoice_extra[index]?.uuid) {
                return;
              } else {
                return {
                  name: list.name,
                  amount: list.amount,
                  invoice: infoForEdit?.invoice.uuid,
                };
              }
            })
            .filter((list) => list !== undefined);
          if (extraPaymentField.length >= 1) {
            postInvoiceExtraMutation.mutate(extraPaymentField);
          }
          // formData.append("passenger", JSON.stringify([]));
          // console.log("payment", payment);
          // console.log("payment2", extraPaymentField);

          response = await axios.put(
            `${BASE_URL}/api/heli-partners/flight-record/`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
            }
          );
        } else {
          if (!isFieldEmpty) {
            setIsFieldEmpty(false);
            response = await axios.post(
              `${BASE_URL}/api/heli-partners/flight-record/`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${localStorage.getItem(
                    "access_token"
                  )}`,
                },
              }
            );
          }
        }

        try {
          const formData1 = new FormData();

          if (editReports) {
            formData1.append("record", infoForEdit?.uuid);

            if (passengerFile?.length >= 1) {
              passengerFile?.map((item) =>
                formData1.append("bulk_document", item)
              );
            }

            let array = infoForEdit.passenger?.filter((list) => {
              return list.name !== null;
            });

            let selectiveArray = passenger.slice(array.length);

            formData1.append(
              "passenger",
              JSON.stringify(
                selectiveArray.map((list) => {
                  return { name: list.name };
                })
              )
            );
            console.log("passengerFile", selectiveArray);
            if (selectiveArray[0]?.image) {
              selectiveArray?.map((list) => {
                return formData1.append("single_document", list.image[0]);
              });
            }
            const res = await axios.post(
              `${BASE_URL}/api/heli-partners/passengers/`,
              formData1,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${localStorage.getItem(
                    "access_token"
                  )}`,
                },
              }
            );
            if (res) {
              dispatch(getSpecificTransactionList(infoForEdit?.uuid));
              history.push("/admin/viewRecord");
            }
          } else {
            formData1.append("record", response.data.data.uuid);
            if (passengerFile?.length >= 1) {
              console.log("nicee111");
              passengerFile?.map((item) =>
                formData1.append("bulk_document", item)
              );
            }

            formData1.append("passenger", JSON.stringify(passengerNameList));

            if (passengerImageList[0]?.image) {
              console.log("passengerNameList", passengerImageList);
              passengerImageList?.map((list) => {
                return formData1.append("single_document", list.image[0]);
              });
            }

            if (!isFieldEmpty) {
              setIsFieldEmpty(false);
              const res = await axios.post(
                `${BASE_URL}/api/heli-partners/passengers/`,
                formData1,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem(
                      "access_token"
                    )}`,
                  },
                }
              );
            }
          }
          if (!editReports) {
            setAmount("");
            setValue("");
            setNote("");
            setPilotName("");
            setFlightNum("");
            setPassenger([{ name: "" }]);
            setLocation([]);
            formik.resetForm();
            setFlight("");
            setFile("");
            setPaymentFile("");
            setPassengerFile("");
            setWeightUnit("");
            setCurrencyUnit("");
          }

          setLoading(false);
        } catch (e) {
          // console.log(e);
          setLoading(false);
          toast({
            title: "Something Went Wrong! kjhlkh",
            position: "top-right",
            // description: "You will shortly contact you.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
        toast({
          title: "Success!",
          position: "top-right",
          // description: "You will shortly contact you.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (e) {
        // console.log(e);
        setLoading(false);
        toast({
          title: "Something Went Wrong!",
          position: "top-right",
          // description: "You will shortly contact you.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } else {
      // post data
      const formData = new FormData();
      formData.append("type_of_flight", JSON.parse(flight).uuid);
      // formData.append("value", value);
      // formData.append("value_unit", weightUnit);
      formData.append("pilot_name", pilotName);

      formData.append("flight_number", flightNum ? flightNum : null);
      formData.append("description", note);
      formData.append("total_minutes", totalMinute);
      formData.append("total_kgs", value);
      formData.append("minute_price", perMinutePrice);
      formData.append("kg_price", perKgPrice);
      if (invoiceImage) {
        formData.append("invoice_image", invoiceImage);
      }

      if (editReports) {
        // formData.append("image", null);
        const cargoImageFormdata = new FormData(); // used for bulk update of image in cargo

        formData.append(
          "invoice",
          JSON.stringify({
            uuid: infoForEdit.invoice.uuid,
            invoice_date: new Date().toISOString().slice(0, 10),
            due_date: new Date().toISOString().slice(0, 10),
            total_amount: amount,
            tax: 0,
            discount: 0,
            invoice_amount: amount,
            device_id: "web",
            unit: currencyUnit,
            is_paid: infoForEdit?.invoice.is_paid?"True":"False"
          })
        );

        formData.append("uuid", infoForEdit.uuid);

        formData.append(
          "location",
          JSON.stringify(
            infoForEdit?.location
              .map((list, index) => {
                return {
                  uuid: list.uuid,
                  name: location[index]?.name,
                  date: location[index]?.date,
                  weight: location[index]?.weight,
                };
              })
              .filter((list) => list.name)
          )
        );

        formData.append(
          "invoice_extra",
          JSON.stringify(
            infoForEdit?.invoice_extra.map((list, index) => {
              return {
                uuid: list.uuid,
                name: payment[index].name,
                amount: payment[index].amount,
              };
            })
          )
        );
        if (cargoImage?.length >= 1) {
          cargoImageFormdata.append("uuid", infoForEdit?.uuid);
          cargoImage?.map((item) => cargoImageFormdata.append("image", item));
          putImageInBulkCargoDetailsMutation.mutate(cargoImageFormdata);
        }
      } else {
        formData.append("invoice_extra", JSON.stringify(payment));
        formData.append("location", JSON.stringify(location));
        formData.append(
          "invoice",
          JSON.stringify({
            invoice_date: new Date().toISOString().slice(0, 10),
            due_date: new Date().toISOString().slice(0, 10),
            total_amount: amount,
            tax: 0,
            discount: 0,
            invoice_amount: amount,
            device_id: "web",
            unit: currencyUnit,
            is_paid: "False"
          })
        );
        if (cargoImage?.length >= 1) {
          cargoImage?.map((item) => formData.append("image", item));
        }
      }

      try {
        if (editReports) {
          const extraLocatonField = addedTransitTracker
            .map((list, index) => {
              if (infoForEdit.location[index]?.uuid) {
                return;
              } else {
                return {
                  name: list.name,
                  date:
                    list.date.split("T")[1] === "undefined"
                      ? list.date.split("T")[0]
                      : list.date,
                  weight: list.weight,
                  record: infoForEdit?.uuid,
                };
              }
            })
            .filter((list) => list !== undefined);

          // console.log("extraLocatonField", extraLocatonField);
          // console.log("extraLocatonField22", location);
          if (extraLocatonField.length >= 1) {
            postTripLocationMutation.mutate(extraLocatonField);
          }
          setLoading(true);
          const extraPaymentField = payment
            .map((list, index) => {
              if (infoForEdit.invoice_extra[index]?.uuid) {
                return;
              } else {
                return {
                  name: list.name,
                  amount: list.amount,
                  invoice: infoForEdit?.invoice.uuid,
                };
              }
            })
            .filter((list) => list !== undefined);
          if (extraPaymentField.length >= 1) {
            postInvoiceExtraMutation.mutate(extraPaymentField);
          }
          const res = await axios.put(
            `${BASE_URL}/api/heli-partners/flight-record/`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              },
            }
          );
          if (res) {
            setLoading(false);
            dispatch(getSpecificTransactionList(infoForEdit?.uuid));
            history.push("/admin/viewRecord");
          }
        } else {
          if (!isFieldEmpty) {
            setIsFieldEmpty(false);
            const res = await axios.post(
              `${BASE_URL}/api/heli-partners/flight-record/`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: `Bearer ${localStorage.getItem(
                    "access_token"
                  )}`,
                },
              }
            );
          }

          setAmount("");
          setValue("");
          setNote("");
          setPilotName("");
          setFlightNum("");
          setPassenger([{ name: "" }]);
          setLocation([]);
          formik.resetForm();
          setTotalMinute("");
          setPerMinutePrice("");
          // formik.values.paymentName.length = 0;
          // formik.values.paymentAmount.length = 0;
          setPayment([]);
          // setFlight("");
          setPerKgPrice("");
          setTotalMinute("");
          setPerMinutePrice("");
          setLoading(false);
          setFile("");
          setPaymentFile("");
          setPassengerFile("");
          setWeightUnit("");
          setCurrencyUnit("");
        }

        toast({
          title: "Success!",
          position: "top-right",
          // description: "You will shortly contact you.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } catch (e) {
        // console.log("something went wrong");
        setLoading(false);
        toast({
          title: "Something Went Wrong!",
          position: "top-right",
          // description: "You will shortly contact you.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  }

  useEffect(() => {
    if (formik.values.paymentAmount || formik.values.paymentName) {
      setPayment([]);
      let totalAmount = 0;
      formik.values.paymentAmount?.forEach((amount) => {
        totalAmount = +amount + totalAmount;
      });

      // console.log("totalAmount", totalAmount);

      setAmount(totalAmount);

      formik.values.paymentName?.map((_, index) => {
        setPayment((oldArray) => [
          ...oldArray,
          {
            name: formik.values.paymentName[index],
            amount: formik.values.paymentAmount[index],
          },
        ]);
      });
    }
  }, [formik.values.paymentName, formik.values.paymentAmount]);

  // console.log("payment", payment);

  // data transform
  const getLocations = (values) => {
    setLocation([]);
    if (editReports) {
      setAddedTransitTracker((oldArray) => [
        ...oldArray,
        {
          name: values.addLocationStartingLocation,
          date: `${values.addLocationStartDate}T${
            values.addLocationStartTime
              ? values.addLocationStartTime
              : "00:00.000000"
          }`,
          weight: values.addLocationStartWeight,
          type: "start",
        },
      ]);

      setAddedTransitTracker((oldArray) => [
        ...oldArray,
        {
          name: values.addLocationDestinationLocation,
          date: `${values.addLocationDestinationDate}T${
            values.addLocationDestinationTime
              ? values.addLocationDestinationTime
              : "00:00.000000"
          }`,
          weight: values.addLocationDestinationWeight,
          type: "end",
        },
      ]);

      values.addLocationTransitLocations?.map((transit, index) => {
        setAddedTransitTracker((oldArray) => [
          ...oldArray,
          {
            name: values.addLocationTransitLocations[index],
            date: `${values.addLocationTransitDate[index]}T${
              Array.isArray(values.addLocationTransitTime) &&
              values.addLocationTransitTime.length > 0
                ? values.addLocationTransitTime[index]
                : "00:00.000000"
            }`,
            weight:
              values.addLocationTransitWeight === undefined
                ? 0
                : values.addLocationTransitWeight[index],
            type: "transit",
          },
        ]);
      });

      setLocation((oldArray) => [
        ...oldArray,
        {
          name: values.addLocationStartingLocation,
          date: `${values.addLocationStartDate}T${
            values.addLocationStartTime
              ? values.addLocationStartTime
              : "00:00.000000"
          }`,
          weight: values.addLocationStartWeight,
          type: "start",
        },
      ]);

      values.addLocationTransitLocations?.map((transit, index) => {
        setLocation((oldArray) => [
          ...oldArray,
          {
            name: values.addLocationTransitLocations[index],
            date: `${values.addLocationTransitDate[index]}T${
              Array.isArray(values.addLocationTransitTime) &&
              values.addLocationTransitTime.length > 0
                ? values.addLocationTransitTime[index]
                : "00:00.000000"
            }`,
            weight:
              values.addLocationTransitWeight === undefined
                ? 0
                : values.addLocationTransitWeight[index],
            type: "transit",
          },
        ]);
      });
      setLocation((oldArray) => [
        ...oldArray,
        {
          name: values.addLocationDestinationLocation,
          date: `${values.addLocationDestinationDate}T${
            values.addLocationDestinationTime
              ? values.addLocationDestinationTime
              : "00:00.000000"
          }`,
          weight: values.addLocationDestinationWeight,
          type: "end",
        },
      ]);
    } else {
      setLocation((oldArray) => [
        ...oldArray,
        {
          name: values.addLocationStartingLocation,
          date: `${values.addLocationStartDate}T${
            values.addLocationStartTime
              ? values.addLocationStartTime
              : "00:00.000000"
          }`,
          weight: values.addLocationStartWeight,
          type: "start",
        },
      ]);

      setLocation((oldArray) => [
        ...oldArray,
        {
          name: values.addLocationDestinationLocation,
          date: `${values.addLocationDestinationDate}T${
            values.addLocationDestinationTime
              ? values.addLocationDestinationTime
              : "00:00.000000"
          }`,
          weight: values.addLocationDestinationWeight,
          type: "end",
        },
      ]);

      values.addLocationTransitLocations?.map((transit, index) => {
        setLocation((oldArray) => [
          ...oldArray,
          {
            name: values.addLocationTransitLocations[index],
            date: `${values.addLocationTransitDate[index]}T${
              Array.isArray(values.addLocationTransitTime) &&
              values.addLocationTransitTime.length > 0
                ? values.addLocationTransitTime[index]
                : "00:00.000000"
            }`,
            weight:
              values.addLocationTransitWeight === undefined
                ? 0
                : values.addLocationTransitWeight[index],
            type: "transit",
          },
        ]);
      });
    }

    // console.log("location", location);
    onClose();
  };

  console.log("location", location);
  // api call

  useEffect(() => {
    if (flight) {
      console.log("flight1111", flight);
      if (JSON.parse(flight).description.toLowerCase() === "passenger") {
        setIsPassenger(true);
      } else {
        setIsPassenger(false);
      }
    }
  }, [flight]);

  console.log("flight2222", flight);

  const element = document.getElementById("scroll");

  function tabHandler() {
    element?.scrollTo(300, 0);
  }
  return (
    <Flex direction="column">
      <Spinner
        pos={"absolute"}
        color="orange"
        zIndex={100}
        top="50%"
        left={"50%"}
        size={"lg"}
        display={loading ? "block" : "none"}
      />
      <Card h="97vh">
        <Box mt="7rem">
          {" "}
          <Grid templateRows={"repeat(2 , 1fr)"} rowGap="2rem">
            <GridItem>
              {dataTypeOfTrip && (
                <Flex alignItems={"center"} gap="1.2rem">
                  <Box w="7rem" fontSize={"sm"}>
                    Type of trip
                  </Box>
                  <Box>
                    <Select
                      // placeholder="Select option"
                      w={{ base: "15rem", md: "15rem", sm: "10rem" }}
                      size={"sm"}
                      onChange={(e) => setFlight(e.target.value)}
                      // value={value}
                      // defaultValue={`{"name":"${dataTypeOfTrip[0]?.name}","description":"${dataTypeOfTrip[0]?.description}","uuid":"${dataTypeOfTrip[0]?.uuid}"}`} //
                      // value={value}
                    >
                      {dataTypeOfTrip
                        ?.sort((a, b) => a.name.localeCompare(b.name))
                        ?.map((data) => {
                          return (
                            <option
                              value={`{"name":"${data.name}","description":"${data.description}","uuid":"${data.uuid}"}`}
                              // value='{"Name":"Jon Doe","Email":"jon@doe.com","Address":"123 Doe Street"}'
                              key={data.uuid}
                              selected={data.name === value ? "true" : ""}
                            >
                              {data.name}
                            </option>
                          );
                        })}
                    </Select>
                  </Box>
                </Flex>
              )}
            </GridItem>

            <GridItem>
              <Flex
                gap="1.2rem"
                alignItems={"center"}
                cursor={"pointer"}
                onClick={() => onOpen()}
              >
                <Box w="7rem" fontSize={"sm"}>
                  Location
                </Box>
                {location?.length >= 1 ? (
                  location.map((loc, index) => (
                    <Box fontSize={"sm"} key={index} display={"contents"}>
                      {loc.name}{" "}
                      {location.length > index + 1 ? (
                        <IoArrowForward color="orange" />
                      ) : (
                        ""
                      )}
                    </Box>
                  ))
                ) : (
                  <Box color="orange.700">Select Location</Box>
                )}
              </Flex>
              {editReports ? (
                <EditLocationModel
                  isOpen={isOpen}
                  onClose={onClose}
                  getLocations={getLocations}
                  isSpecifiedWeight={isSpecifiedWeight}
                  formik={formik}
                  setArrayTest={setArrayTest}
                  arrayTest={arrayTest}
                  infoForEdit={infoForEdit}
                />
              ) : (
                <LocationModal
                  isOpen={isOpen}
                  onClose={onClose}
                  getLocations={getLocations}
                  isSpecifiedWeight={isSpecifiedWeight}
                  formik={formik}
                  setArrayTest={setArrayTest}
                  arrayTest={arrayTest}
                  infoForEdit={infoForEdit}
                />
              )}
            </GridItem>
          </Grid>
          <Tabs mt="1.2rem" overflow={"hidden"}>
            <TabList
              id="scroll"
              variant="unstyled"
              gap={{ sm: "1rem", md: "6rem" }}
              overflowX={{ sm: "scroll", xl: "visible" }}
              overflowY="hidden"
              scrollBehavior="smooth"
              onClick={tabHandler}
              p="1rem"
              bg={"#F8F8F8"}
              borderTop="2px solid #EAEAEA"
              borderBottom="2px solid #EAEAEA"
            >
              <Tab _selected={{ color: "white", bg: "#f77f3c" }}>
                <Text fontSize={{ base: "md", md: "md", sm: "sm" }}>
                  {flight && JSON.parse(flight).name}
                </Text>
                {/* Passenger Details */}
              </Tab>

              <Tab
                _selected={{ color: "white", bg: "#f77f3c" }}
                fontSize={{ base: "md", md: "md", sm: "sm" }}
              >
                Payment Details
              </Tab>
              <Tab
                _selected={{ color: "white", bg: "#f77f3c" }}
                fontSize={{ base: "md", md: "md", sm: "sm" }}
              >
                Flight Details{" "}
              </Tab>
              <Tab
                _selected={{ color: "white", bg: "#f77f3c" }}
                fontSize={{ base: "md", md: "md", sm: "sm" }}
              >
                Remarks{" "}
              </Tab>
            </TabList>

            <TabPanels>
              {isPassenger ? (
                <TabPanel>
                  {editReports ? (
                    <EditPassengerDetails
                      setPassengerData={(value) => setPassengerData(value)}
                      setPassengerImage={(value) => setPassengerImage(value)}
                      setPassenger={setPassenger}
                      passenger={passenger}
                      setPassengerFile={setPassengerFile}
                      passengerFile={passengerFile}
                      infoForEdit={infoForEdit}
                      editReports={editReports}
                      setTotalMinute={setTotalMinute}
                      totalMinute={totalMinute}
                      setPerMinutePrice={setPerMinutePrice}
                      perMinutePrice={perMinutePrice}
                    />
                  ) : (
                    <PassengerDetails
                      setPassengerData={(value) => setPassengerData(value)}
                      setPassengerImage={(value) => setPassengerImage(value)}
                      setPassenger={setPassenger}
                      passenger={passenger}
                      setPassengerFile={setPassengerFile}
                      passengerFile={passengerFile}
                      infoForEdit={infoForEdit}
                      editReports={editReports}
                      setTotalMinute={setTotalMinute}
                      totalMinute={totalMinute}
                      setPerMinutePrice={setPerMinutePrice}
                      perMinutePrice={perMinutePrice}
                    />
                  )}
                </TabPanel>
              ) : (
                <TabPanel>
                  <CargoDetails
                    file={file}
                    setFile={setFile}
                    value={value}
                    openModalWithSpecifiedWeight={openModalWithSpecifiedWeight}
                    unit={unit}
                    weightUnit={weightUnit}
                    editReports={editReports}
                    setValue={setValue}
                    infoForEdit={infoForEdit}
                    setCargoImage={setCargoImage}
                    perKgPrice={perKgPrice}
                    setPerKgPrice={setPerKgPrice}
                    totalMinute={totalMinute}
                    setTotalMinute={setTotalMinute}
                    perMinutePrice={perMinutePrice}
                    setPerMinutePrice={setPerMinutePrice}
                  />
                </TabPanel>
              )}
              <TabPanel>
                <PaymentDetails
                  onChange={(value) => setAmount(value)}
                  setInvoiceImage={(value) => setInvoiceImage(value[0])}
                  value={amount}
                  setPaymentFile={setPaymentFile}
                  paymentFile={paymentFile}
                  setCurrencyUnit={setCurrencyUnit}
                  currencyUnit={currencyUnit}
                  formik={formik}
                  editReports={editReports}
                  infoForEdit={infoForEdit}
                />
              </TabPanel>
              <TabPanel>
                <FlightDetails
                  flightlist={dataGetFlightList}
                  onPilotChange={(value) => setPilotName(value)}
                  onFlightChange={(value) => setFlightNum(value)}
                  pilotName={pilotName}
                  flightNum={flightNum}
                />
              </TabPanel>
              <TabPanel>
                <Grid mt={"1.2rem"} templateRows={"repeat(2 , 1fr)"}>
                  <GridItem>
                    {" "}
                    <Flex flexDirection={"column"}>
                      <Box>
                        {" "}
                        <Text>Notes:</Text>
                      </Box>
                      <Textarea
                        w={{ base: "80%", md: "80%", sm: "100%" }}
                        mt="1rem"
                        onChange={(e) => setNote(e.target.value)}
                        value={note}
                      ></Textarea>
                    </Flex>
                  </GridItem>
                </Grid>
              </TabPanel>
            </TabPanels>
          </Tabs>
          <Flex gap="1rem">
            {/* <Button onClick={() => setFile("")}>test</Button> */}
            <SaveButton onClick={handleSubmit} />
            <CancelButton />
          </Flex>
        </Box>
      </Card>
    </Flex>
  );
};
