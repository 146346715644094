import React from "react";
// Chakra imports
import {
  Flex,
  Grid,
  GridItem,
  Image,
  Box,
  Button,
  Text,
  HStack,
  useColorModeValue,
  Center,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import { SectionTitle } from "components/Common/SectionTitle";
import { SectionDescription } from "components/Common/SectionDescription";
import { useProfileApi } from "../Profile/api/useProfileApi";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import { IoArrowForward } from "react-icons/io5";
import { EditIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "constants/BASE_URL";
import { useEffect } from "react";
import { useState } from "react";
import "react-image-lightbox/style.css";
import Lightbox from "react-image-lightbox";

export const ViewRecord = () => {
  const { dataProfile } = useProfileApi();
  const infoForEdit = useSelector((state) => state.infoForEditFlight.info);
  const history = useHistory();
  const secondaryColor = useColorModeValue("gray.500", "white");

  const [openBoxImage, setOpenBoxImage] = useState(false);

  console.log(infoForEdit);
  function handelEdit() {
    history.push("/admin/editReport");
    // dispatch(infoForEditFlight(infoForEdit));
  }
  let invoiceImage = [];
  let cargoImage = [];
  let passengerImage = [];

  const [allImage, setAllImage] = useState([]);

  const [photoIndex, setPhotoIndex] = useState(0);

  useEffect(() => {
    invoiceImage.push(infoForEdit?.invoice_image);
    cargoImage = infoForEdit?.images?.map((list) => list.image);
    passengerImage = infoForEdit?.passenger?.map((list) => list.image);
    setAllImage([...invoiceImage, ...cargoImage, ...passengerImage]);
  }, [infoForEdit]);

  let filterImage = allImage.filter((list) => list !== null);
  function imageHandler(index) {
    setPhotoIndex(index);
    setOpenBoxImage(true);
  }
  return (
    <Flex direction="column" pos="relative">
      {console.log("allImage", filterImage)}
      <Card
        h="97vh"
        __css={{ padding: "0px", background: "white" }}
        pos="relative"
      >
        <Button
          pos="absolute"
          top={{ sm: "2rem", md: "3rem" }}
          right={{ sm: "1rem", md: "8rem" }}
          rightIcon={<EditIcon color="#f77f3c" />}
          borderStyle="solid"
          borderWidth={"2px"}
          borderColor="#f77f3c"
          borderRadius={"20px"}
          size="sm"
          zIndex="1"
          onClick={() => handelEdit()}
        >
          <Text color="#f77f3c"> Edit</Text>
        </Button>

        <Grid templateColumns="repeat(2, 1fr)" mt="7rem" bg={"#f6f6f6"} p={4}>
          <GridItem>
            <HStack spacing={4}>
              <Image
                src={infoForEdit?.added_by.profile}
                boxSize={"50px"}
                borderRadius="full"
                objectFit="cover"
                border={"1px solid orange"}
              />
              <Text>
                Flight Record Created by{" "}
                {infoForEdit?.added_by.first_name +
                  " " +
                  infoForEdit?.added_by.last_name}
              </Text>
            </HStack>
          </GridItem>
          <GridItem alignSelf={"center"}>
            <Text textAlign={"right"}>
              <Moment format="DD MMM YYYY HH:mm A">
                {infoForEdit?.created_at}
              </Moment>
            </Text>
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(2, 1fr)" pt={4} pr={4} pl={4}>
          <GridItem>
            <SectionTitle title="Type of Trip" />
          </GridItem>
          <GridItem justifySelf={"end"} textAlign="right">
            <Center
              bg={
                infoForEdit?.status === "Pending"
                  ? "yellow.500"
                  : infoForEdit?.status === "Conflict"
                  ? "red"
                  : "green"
              }
              color={"white"}
              borderRadius="3xl"
              // h="2rem"
              p="5px"
              w="8rem"
            >
              {infoForEdit?.status}
            </Center>
          </GridItem>
          <GridItem colSpan={2}>
            <SectionDescription des={infoForEdit?.type_of_flight.name} />
          </GridItem>
          <GridItem colSpan={2} mt={4}>
            <SectionTitle title="Flight Location" />
          </GridItem>
          <GridItem colSpan={2}>
            <Flex alignItems={"center"} gap="1.2rem">
              {infoForEdit?.location.map((loc, index) => (
                <Box fontSize={"sm"} key={index} display={"contents"}>
                  <SectionDescription des={loc.name} />
                  {infoForEdit?.location.length > index + 1 ? (
                    <IoArrowForward color="orange" />
                  ) : (
                    ""
                  )}
                </Box>
              ))}
            </Flex>
          </GridItem>
          <GridItem colSpan={2} mt={4}>
            <SectionTitle title={infoForEdit?.type_of_flight?.name} />
          </GridItem>
          <GridItem colSpan={2}>
            <Text color={secondaryColor}>
              {" "}
              Total Minutes : {infoForEdit?.total_minutes}mins @Rs.
              {infoForEdit?.minute_price}/min
            </Text>
            {infoForEdit?.passenger.length >= 1 ? (
              infoForEdit?.passenger.map((list) => {
                return <Text color={secondaryColor}>{list.name}</Text>;
              })
            ) : (
              <Text color={secondaryColor}>
                {" "}
                Total Kg: {infoForEdit?.total_kgs}kg @Rs.
                {infoForEdit?.kg_price}/kg
              </Text>
            )}
          </GridItem>

          <GridItem colSpan={2} mt={4}>
            <SectionTitle title="Flight Details" />
          </GridItem>
          <GridItem colSpan={2}>
            <SectionDescription
              des={
                "Pilot Name : " +
                (infoForEdit?.pilot_name !== "null"
                  ? infoForEdit.pilot_name
                  : "N/A")
              }
            />
            <SectionDescription
              des={
                "Flight Number : " +
                (infoForEdit?.flight_number &&
                infoForEdit?.flight_number !== "null"
                  ? infoForEdit.flight_number
                  : "N/A")
              }
            />
          </GridItem>
        </Grid>
        <Grid templateColumns="repeat(3, 1fr)" p={4}>
          <GridItem colSpan={2}>
            <SectionTitle title="Remarks" />
            <SectionDescription
              des={
                infoForEdit?.description !== "null"
                  ? infoForEdit.description
                  : "N/A"
              }
            />

            <Box mt={4}>
              <SectionTitle title="Uploads" />
              <Grid templateColumns="repeat(6, 1fr)" gap={2}>
                {allImage
                  .filter((list) => list !== null)
                  .map((list, indx) => {
                    return (
                      <GridItem>
                        <Image
                          key={indx}
                          src={list}
                          objectFit={"cover"}
                          height={"5rem"}
                          width={"6rem"}
                          borderRadius={"20px"}
                          border={"1px solid orange"}
                          onClick={() => imageHandler(indx)}
                          cursor="pointer"
                        />
                      </GridItem>
                    );
                  })}
              </Grid>
            </Box>
          </GridItem>
          <GridItem colSpan={1} textAlign={"right"}>
            <SectionTitle title="Total Billed Amount" />
            <Text fontSize={"3xl"} color="orange">
              {(infoForEdit?.invoice?.unit === "None" ||
              infoForEdit?.invoice?.unit === null
                ? "$"
                : infoForEdit?.invoice?.unit) +
                " " +
                infoForEdit?.invoice?.total_amount}
            </Text>
            {infoForEdit?.invoice_extra.map((inv, index) => (
              <Box key={index}>
                <SectionDescription
                  des={
                    inv.name +
                    " : " +
                    (infoForEdit?.invoice?.unit === "None" ||
                    infoForEdit?.invoice?.unit === null
                      ? "$"
                      : infoForEdit?.invoice?.unit) +
                    " " +
                    inv.amount
                  }
                />
              </Box>
            ))}
          </GridItem>
        </Grid>
      </Card>

      {openBoxImage && (
        <Lightbox
          mainSrc={filterImage[photoIndex]}
          nextSrc={filterImage[(photoIndex + 1) % filterImage.length]}
          prevSrc={
            filterImage[
              (photoIndex + filterImage.length - 1) % filterImage.length
            ]
          }
          onCloseRequest={() => setOpenBoxImage(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + filterImage.length - 1) % filterImage.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % filterImage.length)
          }
        />
      )}
    </Flex>
  );
};
