import axios from "axios";
import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery, useMutation } from "react-query";

export const useGetLocation = () => {
  async function getLocation() {
    const res = await authorizationAxios.get(
      `${BASE_URL}/api/location/?date=2000-1-1T1:1:1`
    );
    return res.data;
  }
  const {
    data: dataGetLocation,
    error,
    isError,
    isLoading,
    refetch,
  } = useQuery("getLocation", getLocation);

  return {
    dataGetLocation,
    refetch,
  };
};
