import axios from "axios";
import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery, useMutation } from "react-query";

export const useProfileApi = () => {
  async function getProfile() {
    const res = await authorizationAxios.get(
      `${BASE_URL}/api/v1.1/profile/`
    );
    return res.data;
  }
  const { data: dataProfile, error, isError, isLoading } = useQuery(
    "getProfile",
    getProfile
  );

  return {
    dataProfile,
  };
};
