import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery, useMutation } from "react-query";

export const useGetStatusList = () => {
  async function getStatusList() {
    const res = await authorizationAxios.get(`${BASE_URL}/api/status-type/`);
    return res.data;
  }
  const { data: dataGetStatusList, error, isError, isLoading } = useQuery(
    "getStatusList",
    getStatusList
  );

  return {
    dataGetStatusList,
  };
};
