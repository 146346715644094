import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Grid,
  GridItem,
  Flex,
  FormControl,
  FormLabel,
  useDisclosure,
  Text,
  HStack,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";

import { Formik, useFormik } from "formik";
import { InputField } from "components/Common/InputField";
import { ButtonWithOutStyle } from "components/Common/ButtonWithOutStyle";
import { CancelButton } from "components/Common/CancelButton";
import { PageTitle } from "components/Common/PageTitle";
import { useGetLocation } from "../API/useGetLocation";
import { Select } from "@chakra-ui/react";
import { SubmitButton } from "components/Common/SubmitButton";
import { IoCloseCircleOutline } from "react-icons/io5";
import { AddLocation } from "./AddLocation";
// import DatePicker from "react-date-picker";
import { AiFillCalendar } from "react-icons/ai";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../Record Flights/Components/LocationModal.css";

const CustomInput = React.forwardRef((props, ref) => {
  return (
    <Flex
      onClick={props.onClick}
      ref={ref}
      border="0.1px solid #e5e4e4"
      w="100%"
      h="2.4rem"
      justifyContent={"space-between"}
      // justifyItems={"center"}
      alignItems="center"
      padding={"0.3rem"}
      borderRadius="4px"
      fontSize={"sm"}
      cursor="pointer "
    >
      {" "}
      <Text>{props.value || props.placeholder}</Text>
      <AiFillCalendar onClick={props.onClick} />
    </Flex>
  );
});

export const LocationModal = ({
  isOpen,
  onClose,
  getLocations,
  isSpecifiedWeight,
  formik,
  setArrayTest,
  arrayTest,
  infoForEdit,
}) => {
  const { dataGetLocation } = useGetLocation();
  const [startDate, setStartDate] = useState(new Date());
  const [destinationDate, setDestinationDate] = useState(new Date());
  const [transitDate, setTransitDate] = useState("");
  const [transitDateArray, setTransitDateArray] = useState([]);

  const [DateForPreFilling, setDateForPreFilling] = useState();

  let arrayTest2 = [];

  const {
    isOpen: addlocationIsOpen,
    onOpen: addlocationOnOpen,
    onClose: addlocationOnClose,
  } = useDisclosure();

  const removeTransitLocation = (index) => {
    setArrayTest(arrayTest.filter((item, i) => i !== index));
    formik.values.addLocationTransitLocations = formik.values.addLocationTransitLocations.filter(
      (item, i) => i !== index
    );
    formik.values.addLocationTransitDate = formik.values.addLocationTransitDate.filter(
      (item, i) => i !== index
    );
    formik.values.addLocationTransitTime = formik.values.addLocationTransitTime.filter(
      (item, i) => i !== index
    );
    formik.values.addLocationTransitWeight = formik.values.addLocationTransitWeight.filter(
      (item, i) => i !== index
    );
  };

  const [transitIndex, setTransitIndex] = useState(); //
  const [dynamicFieldIndex, setDynamicFieldIndex] = useState(-1);

  useEffect(() => {
    if (
      formik.values.addLocationStartingLocation === "addLocation" ||
      formik.values.addLocationDestinationLocation === "addLocation" ||
      formik.values.addLocationTransitLocations[transitIndex] === "addLocation"
    ) {
      addlocationOnOpen(true);
    }
  }, [
    formik.values.addLocationStartingLocation,
    formik.values.addLocationDestinationLocation,
    formik.values.addLocationTransitLocations[transitIndex],
  ]);

  const tempArray = useRef([]);

  useEffect(() => {
    setDateForPreFilling(transitDate ? transitDate : destinationDate);
    formik.values.addLocationStartDate = moment(new Date(startDate)).format(
      "YYYY-MM-DD"
    );
    formik.values.addLocationDestinationDate = moment(
      new Date(destinationDate)
    ).format("YYYY-MM-DD");

    // logic is used for automatically adding date to the dynamic field of transit date.
    if (transitDate !== "") {
      tempArray.current[transitIndex] = transitDate;

      setTransitDateArray([...tempArray.current]);
    }
  }, [startDate, destinationDate, transitDate]);
  formik.values.addLocationTransitDate = transitDateArray.map((date) => {
    return moment(new Date(date)).format("YYYY-MM-DD");
  });

  // this logic is used for preselect of automatic date(date which is get when "Add Another Location" is clicked) to send to api
  useEffect(() => {
    if (transitDateArray[dynamicFieldIndex] === undefined) {
      tempArray.current[dynamicFieldIndex] = DateForPreFilling;
      setTransitDateArray([...tempArray.current]);
    }
  }, [DateForPreFilling, dynamicFieldIndex]);

  // this logic is used for preselect of automatic time(time which is get when "Add Another Location" is clicked) to send to api
  useEffect(() => {
    if (dynamicFieldIndex === 0) {
      formik.values.addLocationTransitTime[dynamicFieldIndex] =
        formik.values.addLocationDestinationTime;
    } else {
      formik.values.addLocationTransitTime[dynamicFieldIndex] =
        formik.values.addLocationTransitTime[dynamicFieldIndex - 1];
    }
  }, [dynamicFieldIndex]);

  // console.log("change", tempArray, transitIndex);
  console.log("change", transitDateArray);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <PageTitle title={"Add Location"} />
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <form onSubmit={formik.handleSubmit}>
              <>
                <Grid
                  templateColumns={
                    isSpecifiedWeight ? "repeat(4, 1fr)" : "repeat(3, 1fr)"
                  }
                  columnGap="2rem"
                  w="90%"
                >
                  <GridItem colSpan={{ base: 1, md: 1, sm: 2 }}>
                    <FormControl>
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        Starting Location
                      </FormLabel>
                      <Select
                        placeholder="Select option"
                        name={`addLocationStartingLocation`}
                        id={`addLocationStartingLocation`}
                        onChange={formik.handleChange}
                        // defaultValue="niceee"
                        value={formik.values.addLocationStartingLocation}
                        // onClick={addLocationHandler}
                      >
                        {dataGetLocation?.data.map((data, index) => (
                          <option
                            key={index}
                            value={data.name}
                            selected={
                              infoForEdit?.location[0].name ===
                              formik.values.addLocationStartingLocation
                                ? "true"
                                : ""
                            }
                          >
                            {data.name}
                          </option>
                        ))}
                        <option
                          value="addLocation"
                          style={{ color: "#f77f3c" }}
                        >
                          Add Location
                        </option>
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={{ base: 1, md: 1, sm: 2 }}>
                    <Text fontSize="sm" fontWeight="normal" mb="0.5rem">
                      Date
                    </Text>
                    <DatePicker
                      name={`addLocationStartDate`}
                      selected={Date.parse(startDate)}
                      placeholderText="mm/dd/yyyy"
                      customInput={<CustomInput />}
                      onChange={setStartDate}
                      // value={formik.values.addLocationStartDate}
                    />
                  </GridItem>
                  <GridItem colSpan={{ base: 1, md: 1, sm: 2 }}>
                    <InputField
                      label="Time"
                      type="time"
                      name={`addLocationStartTime`}
                      id={`addLocationStartTime`}
                      onChange={formik.handleChange}
                      value={formik.values.addLocationStartTime}
                    />
                  </GridItem>
                  {isSpecifiedWeight ? (
                    <GridItem colSpan={{ base: 1, md: 1, sm: 2 }}>
                      <InputField
                        label="Total Weight"
                        type="number"
                        name={`addLocationStartWeight`}
                        id={`addLocationStartWeight`}
                        onChange={formik.handleChange}
                        value={formik.values.addLocationStartWeight}
                      />
                    </GridItem>
                  ) : (
                    ""
                  )}
                </Grid>

                <Grid
                  templateColumns={
                    isSpecifiedWeight ? "repeat(4, 1fr)" : "repeat(3, 1fr)"
                  }
                  columnGap="2rem"
                  w="90%"
                >
                  <GridItem colSpan={{ base: 1, md: 1, sm: 2 }}>
                    <FormControl>
                      <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                        {arrayTest.length >= 1
                          ? "Transit Location"
                          : " Destination Location"}
                      </FormLabel>

                      <Select
                        placeholder="Select option"
                        name={`addLocationDestinationLocation`}
                        id={`addLocationDestinationLocation`}
                        onChange={formik.handleChange}
                        value={formik.values.addLocationDestinationLocation}
                      >
                        {dataGetLocation?.data.map((data, index) => (
                          <option key={index} value={data.name}>
                            {data.name}
                          </option>
                        ))}
                        <option
                          value="addLocation"
                          style={{ color: "#f77f3c" }}
                        >
                          Add Location
                        </option>
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={{ base: 1, md: 1, sm: 2 }}>
                    <Text fontSize="sm" fontWeight="normal" mb="0.5rem">
                      Date
                    </Text>

                    <DatePicker
                      selected={Date.parse(destinationDate)}
                      placeholderText="mm/dd/yyyy"
                      customInput={<CustomInput />}
                      onChange={setDestinationDate}
                      // value={formik.values.addLocationStartDate}
                    />
                  </GridItem>
                  <GridItem colSpan={{ base: 1, md: 1, sm: 2 }}>
                    <InputField
                      label="Time"
                      type="time"
                      name={`addLocationDestinationTime`}
                      id={`addLocationDestinationTime`}
                      onChange={formik.handleChange}
                      value={formik.values.addLocationDestinationTime}
                    />
                  </GridItem>
                  {isSpecifiedWeight ? (
                    <GridItem colSpan={{ base: 1, md: 1, sm: 2 }}>
                      <InputField
                        label="Total Weight"
                        type="number"
                        name={`addLocationDestinationWeight`}
                        id={`addLocationDestinationWeight`}
                        onChange={formik.handleChange}
                        value={formik.values.addLocationDestinationWeight}
                      />
                    </GridItem>
                  ) : (
                    ""
                  )}
                  {/* <Button bg="green.400" onClick={() => remove(index)}>
                            Remove Location
                          </Button> */}
                </Grid>

                {arrayTest?.map((currElement, index) => {
                  return (
                    <Grid
                      key={index}
                      templateColumns={
                        isSpecifiedWeight ? "repeat(4, 1fr)" : "repeat(3, 1fr)"
                      }
                      columnGap="2rem"
                      w="90%"
                    >
                      <GridItem colSpan={{ base: 1, md: 1, sm: 2 }}>
                        <FormControl>
                          <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                            {arrayTest.length === 1
                              ? "Destination Location"
                              : arrayTest.length - 1 === index
                              ? "Destination Location"
                              : "Transit Location"}
                          </FormLabel>
                          <Select
                            placeholder="Select option"
                            name={`addLocationTransitLocations.${index}`}
                            id={`addLocationTransitLocations.${index}`}
                            onChange={formik.handleChange}
                            value={
                              formik.values.addLocationTransitLocations ===
                              undefined
                                ? ""
                                : formik.values.addLocationTransitLocations[
                                    index
                                  ]
                            }
                            onClick={() => {
                              setTransitIndex(index);
                            }}
                          >
                            {dataGetLocation?.data.map((data, index) => (
                              <option key={index} value={data.name}>
                                {data.name}
                              </option>
                            ))}
                            <option
                              value="addLocation"
                              style={{ color: "#f77f3c" }}
                            >
                              Add Location
                            </option>
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem colSpan={{ base: 1, md: 1, sm: 2 }}>
                        <Text fontSize="sm" fontWeight="normal" mb="0.5rem">
                          Date
                        </Text>
                        <Box
                          onClick={() => {
                            setTransitIndex(index);
                          }}
                        >
                          {" "}
                          <DatePicker
                            // selected={Date.parse(destinationDate)}
                            selected={Date.parse(
                              transitDateArray[index] === undefined
                                ? DateForPreFilling
                                : transitDateArray[index]
                            )}
                            placeholderText="mm/dd/yyyy"
                            customInput={<CustomInput />}
                            onChange={setTransitDate}
                          />
                          {console.log("DateForPreFilling", transitDate)}
                        </Box>
                      </GridItem>
                      <GridItem
                        colSpan={{ base: 1, md: 1, sm: 2 }}
                        display={"inline-flex"}
                        alignItems={"center"}
                      >
                        <InputField
                          label="Time"
                          type="time"
                          name={`addLocationTransitTime.${index}`}
                          id={`addLocationTransitTime.${index}`}
                          onChange={formik.handleChange}
                          value={
                            formik.values.addLocationTransitTime === undefined
                              ? ""
                              : formik.values.addLocationTransitTime[index]
                          }
                        />

                        {isSpecifiedWeight ? (
                          ""
                        ) : (
                          <IoCloseCircleOutline
                            color={"red"}
                            fontSize={"1rem"}
                            style={{ marginLeft: "1rem", cursor: "pointer" }}
                            onClick={() => removeTransitLocation(index)}
                          />
                        )}
                      </GridItem>
                      {isSpecifiedWeight ? (
                        <GridItem
                          colSpan={{ base: 1, md: 1, sm: 2 }}
                          display={"inline-flex"}
                          alignItems={"center"}
                        >
                          <InputField
                            label="Total Weight"
                            type="number"
                            name={`addLocationTransitWeight.${index}`}
                            id={`addLocationTransitWeight.${index}`}
                            onChange={formik.handleChange}
                            value={
                              formik.values.addLocationTransitWeight ===
                              undefined
                                ? 0
                                : formik.values.addLocationTransitWeight[index]
                            }
                          />
                          <IoCloseCircleOutline
                            color={"red"}
                            fontSize={"1rem"}
                            style={{ marginLeft: "1rem", cursor: "pointer" }}
                            onClick={() => removeTransitLocation(index)}
                          />
                          {/* onClick={() => setArrayTest(arrayTest.filter((item,i) => i !== index))} */}
                        </GridItem>
                      ) : (
                        ""
                      )}
                    </Grid>
                  );
                })}
                <Box
                  color="#f77f3c"
                  cursor={"pointer"}
                  onClick={() => {
                    arrayTest2.push(arrayTest + 1);
                    setArrayTest((oldArray) => [...oldArray, arrayTest2]);
                    // setTransitDate("");
                    setDynamicFieldIndex(dynamicFieldIndex + 1);
                  }}
                  fontSize="sm"
                  mt="1rem"
                >
                  Add Another Location
                </Box>
              </>
              <Flex gap="1.5rem" pb={"1rem"} mt="-2rem">
                <SubmitButton />
                {/* <button type="submit">Submit</button> */}
                <CancelButton onClose={onClose} />
              </Flex>
            </form>
          </Box>
          {addlocationIsOpen && (
            <AddLocation
              isOpen={addlocationIsOpen}
              onClose={addlocationOnClose}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
