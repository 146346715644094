import React from "react";
import { Redirect, useLocation } from "react-router-dom";

function RequireAuth({ children }) {
  const authed = localStorage.getItem("access_token");
  const location = useLocation();

  return authed ? (
    children
  ) : (
    <Redirect to="/signin" replace state={{ path: location.pathname }} />
  );
}

export default RequireAuth;
