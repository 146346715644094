import { Grid, GridItem,Flex,Box,Select } from "@chakra-ui/react";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { IconName } from "react-icons/io5";
import React from "react";
import { LeftSideLabelSelect } from "components/Common/LeftSideLabelSelect";

export const FlightDetails = ({onPilotChange,onFlightChange,flightNum,pilotName,flightlist}) => {
  return (
    <Grid templateColumns={"repeat(2,1fr)"} mt="1.2rem">
      <GridItem>
        {" "}
        <Grid templateRows={"1fr ,1fr"} rowGap="1rem">
          <GridItem>
            <LeftSideLabelInput
              label="Pilot Name"
              onChange={onPilotChange}
              type="text"
              value={pilotName}
            />
          </GridItem>
          <GridItem>
            <Flex alignItems={"center"} gap="1.2rem">
              <Box w="7rem" fontSize={"sm"}>
              Flight Number
              </Box>
              <Box>
                <Select  placeholder="Select option" w={{base:"15rem",md:"15rem",sm:"10rem"}} size={"sm"} onChange={(e)=>onFlightChange(e.target.value)} value={flightNum}>
                  {flightlist?.data?.map((data,index) => {
                    return (
                      <option value={data.serial_number} key={index}>
                        {data.serial_number}
                      </option>
                    );
                  })}
                </Select>
              </Box>
            </Flex>
          </GridItem>
        </Grid>
      </GridItem>
    </Grid>
  );
};
