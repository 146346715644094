import React from "react";
import { FormControl, FormLabel, Input } from "@chakra-ui/react";
import { useRef } from "react";
import { useEffect } from "react";

export const InputField = ({ label, type, value, onChange, name, id }) => {
  // const inputDate = document.getElementById(id);

  // // inputDate.addEventListener("focus", function (evt) {
  // //   if (this.getAttribute("type") === "time") {
  // //     this.showPicker();
  // //   }
  // // });

  const inputRef = useRef();

  return (
    <>
      {" "}
      <FormControl>
        <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
          {label}
        </FormLabel>
        <Input
          ref={inputRef}
          variant="auth"
          fontSize="sm"
          ms="4px"
          name={name}
          id={id}
          type={type}
          placeholder="Your full name"
          mb="24px"
          size="sm"
          value={value}
          onChange={onChange}
          onClick={() => {
            inputRef.current.showPicker();
          }}
          cursor="pointer"
        />
      </FormControl>
    </>
  );
};
