// Chakra Icons
// Chakra Imports
import {
  Button,
  Flex,
  toast,
  useColorMode,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";

// Custom Icons
import { IoLogOutOutline } from "react-icons/io5";
// Custom Components
import { SidebarResponsive } from "components/Sidebar/Sidebar";
import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import routes from "routes.js";
import { Profile } from "components/Common/Profile";
import { useProfileApi } from "../../views/Pages/Profile/api/useProfileApi";

export default function HeaderLinks(props) {
  const {
    variant,
    children,
    fixed,
    scrolled,
    secondary,
    onOpen,
    ...rest
  } = props;

  const { colorMode } = useColorMode();
  const history = useHistory();

  // Chakra Color Mode
  let navbarIcon =
    fixed && scrolled
      ? useColorModeValue("black", "black")
      : useColorModeValue("black", "black");
  let menuBg = useColorModeValue("black", "orange.700");
  if (secondary) {
    navbarIcon = "black";
  }

  const { dataProfile } = useProfileApi();
  // console.log(dataProfile);
  const toast = useToast();

  function logoutHandler() {
    toast({
      title: "Logout Successfully!",
      position: "top-right",
      // description: "You will shortly contact you.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    localStorage.setItem("access_token", "");
    localStorage.setItem("refresh_token", "");
    history.push("/signin");
  }

  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
    >
      <SidebarResponsive
        hamburgerColor={"black"}
        logo={
          // <></>
          <Profile data={dataProfile?.data} />
        }
        colorMode={colorMode}
        secondary={props.secondary}
        routes={routes}
        {...rest}
      />

      <Button
        ms="0px"
        px="0px"
        color={"black"}
        variant="no-effects"
        rightIcon={
          document.documentElement.dir ? (
            ""
          ) : (
            // <ProfileIcon color={"black"} fontSize={"1.5rem"} me='0px' />
            <IoLogOutOutline color={"black"} fontSize={"1.5rem"} me="0px" />
          )
        }
        leftIcon={
          document.documentElement.dir ? (
            // <ProfileIcon color={"black"} me='0px' />
            <IoLogOutOutline color={"black"} fontSize={"1.5rem"} me="0px" />
          ) : (
            ""
          )
        }
        onClick={logoutHandler}
      ></Button>

      {/* <SettingsIcon
        cursor='pointer'
        ms={{ base: "16px", xl: "0px" }}
        me='16px'
        onClick={props.onOpen}
        color={navbarIcon}
        w='18px'
        h='18px'
      /> */}
      {/* <Menu>
        <MenuButton>
          <BellIcon color={navbarIcon} w='18px' h='18px' />
        </MenuButton>
        <MenuList p='16px 8px' bg={menuBg}>
          <Flex flexDirection='column'>
            <MenuItem borderRadius='8px' mb='10px'>
              <ItemContent
                time='13 minutes ago'
                info='from Alicia'
                boldInfo='New Message'
                aName='Alicia'
                aSrc={avatar1}
              />
            </MenuItem>
            <MenuItem borderRadius='8px' mb='10px'>
              <ItemContent
                time='2 days ago'
                info='by Josh Henry'
                boldInfo='New Album'
                aName='Josh Henry'
                aSrc={avatar2}
              />
            </MenuItem>
            <MenuItem borderRadius='8px'>
              <ItemContent
                time='3 days ago'
                info='Payment succesfully completed!'
                boldInfo=''
                aName='Kara'
                aSrc={avatar3}
              />
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu> */}
    </Flex>
  );
}
