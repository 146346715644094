import axios from "axios";
import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery, useMutation } from "react-query";

export const useTypeOfTrip = () => {
  async function getTypeOfTrip() {
    const res = await authorizationAxios.get(`${BASE_URL}/api/flight-type/`);
    return res.data;
  }
  const { data: dataTypeOfTrip } = useQuery("getTypeOfTrip", getTypeOfTrip);

  return {
    dataTypeOfTrip,
  };
};
