import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery, useMutation } from "react-query";

export const useGetSpecificTransactionList = (specificDataUuid) => {
  async function getSpecificTransactionList(specificDataUuid) {
    if (specificDataUuid !== null) {
      const res = await authorizationAxios.get(
        `${BASE_URL}/api/heli-partners/transaction-list/?uuid=${specificDataUuid}`
      );
      return res.data;
    }
  }
  const {
    data: dataGetSpecificTransactionList,
    error,
    isError,
    isLoading: transactionListIsLoading,
  } = useQuery(["getSpecificTransactionList", specificDataUuid], () =>
    getSpecificTransactionList(specificDataUuid)
  );

  return {
    dataGetSpecificTransactionList,
  };
};
