import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const recordFlightSlice = createSlice({
  name: "recordFlightSlice",
  initialState: { loading: false },

  reducers: {
    recordFlight: (state, { payload }) => {
      // state.checkOutInfoPersist = payload;
      state.recordFlight = payload;
    },
  },
});

export const recordFlightReducer = recordFlightSlice.reducer;
export const { recordFlight } = recordFlightSlice.actions;
