import { Text } from "@chakra-ui/react";
import React from "react";

export const SectionDescription = ({ des }) => {
  return (
    <Text fontSize={"md"} color="gray">
      {des}
    </Text>
  );
};
