import axios from "axios";
import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery, useMutation } from "react-query";

export const useGetFlightNumber = () => {
  async function getFlightNumberList() {
    const res = await authorizationAxios.get(
      `${BASE_URL}/api/heli-partners/helicopter/`
    );
    return res.data;
  }
  const { data: dataGetFlightList, error, isError, isLoading } = useQuery(
    "getFlightNumberList",
    getFlightNumberList
  );

  return {
    dataGetFlightList,
  };
};
