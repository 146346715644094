import axios from "axios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery, useMutation } from "react-query";

export const usePostLocation = () => {
  async function postLocation(values) {
    const res = await axios.post(`${BASE_URL}/api/location/`, values);
    return res;
  }

  const postLocationMutation = useMutation(postLocation);

  return {
    postLocationMutation,
  };
};
