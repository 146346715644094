import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery, useMutation } from "react-query";

export const usePutImageInBulkCargoDetails = () => {
  async function putImageInBulkCargoDetails(values) {
    const res = await authorizationAxios.post(
      `${BASE_URL}/api/heli-partners/flight-images/ `,
      values
    );
    return res;
  }

  const putImageInBulkCargoDetailsMutation = useMutation(
    putImageInBulkCargoDetails
  );

  return {
    putImageInBulkCargoDetailsMutation,
  };
};
