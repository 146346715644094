import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useSignInApi } from "./api/useSignInApi";
import { signIn } from "./slice/signInSlice";

export const useSignIn = () => {
  const { postSignInMutation } = useSignInApi();
  const { isLoading, isError, data, error, isSuccess } = postSignInMutation;
  const toast = useToast();
  const toastIdRef = useRef();
  const dispatch = useDispatch();
  let history = useHistory();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    validateOnChange: false,
    onSubmit: (values, { resetForm }) => {
      postSignInMutation.mutate(values);
      resetForm();
    },
  });
  useEffect(() => {
    if (isSuccess) {
      dispatch(signIn(data.data));
      toast.update(toastIdRef.current, {
        description: postSignInMutation.data.data.message,
      });
      localStorage.setItem("access_token", data.data.data.access_token);
      localStorage.setItem("refresh_token", data.data.data.refresh_token);
      console.log("access_token", data.data.data.access_token);
      history.push("/admin/recordFlights");
    }

    if (isLoading) {
      toastIdRef.current = toast({
        title: "loading.....",
        position: "top-right",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
    if (isError) {
      toast({
        title: error.message,
        position: "top-right",
        // description: "You will shortly contact you.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  }, [
    postSignInMutation.isSuccess,
    postSignInMutation.isLoading,
    postSignInMutation.isError,
  ]);

  return {
    formik,
    toast,
  };
};
