import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery, useMutation } from "react-query";

export const useGetTransactionList = (statusUuid, pageNumber) => {
  async function getTransactionList(statusUuid, pageNumber) {
    const res = await authorizationAxios.get(
      `${BASE_URL}/api/heli-partners/transaction-list/?page_size=12&page_number=${pageNumber}&${
        statusUuid ? `status=${statusUuid}` : ""
      }`
    );
    return res.data;
  }
  const {
    data: dataGetTransactionList,
    error,
    isError,
    isLoading: transactionListIsLoading,
  } = useQuery(["getTransactionList", statusUuid, pageNumber], () =>
    getTransactionList(statusUuid, pageNumber)
  );

  return {
    dataGetTransactionList,
    transactionListIsLoading,
  };
};
