import {
  Flex,
  Grid,
  GridItem,
  Box,
  Input,
  Image,
  useToast,
  Text,
  FormLabel,
} from "@chakra-ui/react";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import React from "react";
import { useState } from "react";
import { useFormik } from "formik";
import { useEffect } from "react";
// import { UploadBulkImagePassenger } from "./UploadBulkImagePassenger";
// import { useDeletePassengerImage } from "../API/useDeletePassengerImage";
// import { useUpdateSingleImagePassenger } from "../API/useUpdateSingleImagePassenger";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { UploadBulkImagePassenger } from "views/Pages/Record Flights/Components/UploadBulkImagePassenger";
import { useDeletePassengerImage } from "views/Pages/Record Flights/API/useDeletePassengerImage";
import { useUpdateSingleImagePassenger } from "views/Pages/Record Flights/API/useUpdateSingleImagePassenger";
import { getSpecificTransactionList } from "../slice/editFlightSlice";
// import { getSpecificTransactionList } from "views/Pages/Reports/slice/editFlightSlice";

export const EditPassengerDetails = ({
  setPassengerData,
  setPassengerImage,
  setPassenger,
  passenger,
  setPassengerFile,
  passengerFile,
  infoForEdit,
  editReports,
  setTotalMinute,
  setPerMinutePrice,
  totalMinute,
  perMinutePrice,
}) => {
  const { deletePassengerImageMutation } = useDeletePassengerImage();
  const { putSingleImagePassengersMutation } = useUpdateSingleImagePassenger();
  const {
    isSuccess: isDeleteIsSucess,
    isLoading: isDeleteIsLoading,
  } = deletePassengerImageMutation;
  const {
    isSuccess: isUpdateImageSucess,
    isLoading: isUpdateImageLoading,
  } = putSingleImagePassengersMutation;

  const [file, setFile] = useState(null);
  const [singleImage, setSingleImage] = useState();
  const dispatch = useDispatch();
  const toast = useToast();

  useEffect(() => {
    if (isDeleteIsSucess) {
      dispatch(getSpecificTransactionList(infoForEdit?.uuid));
      toast({
        title: "Deleted Successfully!",
        position: "top-right",
        status: "success",
        duration: 1000,
        isClosable: true,
      });
    }

    if (isUpdateImageSucess && uuidOfImage.current) {
      dispatch(getSpecificTransactionList(infoForEdit?.uuid));
      toast({
        title: "Updated Successfully!",
        position: "top-right",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }

    if (isUpdateImageLoading && uuidOfImage.current) {
      dispatch(getSpecificTransactionList(infoForEdit?.uuid));
      toast({
        title: "updating image...",
        position: "top-right",
        status: "info",
        duration: 1000,
        isClosable: true,
      });
    }

    if (isDeleteIsLoading) {
      toast({
        title: "deleting...",
        position: "top-right",
        status: "info",
        duration: 1000,
        isClosable: true,
      });
    }
  }, [
    isDeleteIsSucess,
    isDeleteIsLoading,
    isUpdateImageSucess,
    isUpdateImageLoading,
  ]);

  console.log("file", file);

  const updateList = (value, index) => {
    const updatedArr = passenger.map((val, idx) =>
      idx === index ? { name: value, image: val.image } : val
    );
    setPassenger(updatedArr);
    setPassengerData(updatedArr);
    console.log("event", updatedArr);
  };

  const hiddenFileInput = React.useRef(null);

  const handleClick = (index) => {};

  function deletePassengerImage(uuid) {
    deletePassengerImageMutation.mutate(uuid);
    // console.log("uuid", uuid);
  }

  function handleUpload(event, index) {
    const updatedArr = passenger.map((val, idx) =>
      idx === index
        ? { name: val.name, image: Array.from(event.target.files) }
        : val
    );

    setPassenger(updatedArr);
    setPassengerData(updatedArr);
    setSingleImage(Array.from(event.target.files));
    console.log("Array.from(event.target.files)", updatedArr);
  }
  let uuidOfImage = useRef();
  function updateSingleImage(uuid) {
    uuidOfImage.current = uuid;
  }

  useEffect(() => {
    if (singleImage) {
      const fromdata = new FormData();
      fromdata.append("image", singleImage[0]);
      fromdata.append("uuid", uuidOfImage.current);
      putSingleImagePassengersMutation.mutate(fromdata);
    }
  }, [singleImage]);

  console.log("singleImage", singleImage);

  // varibale is used for only selecting the image with name field only
  const filterImage = infoForEdit?.passenger
    .filter((item) => item.name !== null)
    .map((item, index) => {
      return { image: item.image, index };
    });

  return (
    <Grid templateColumns={"repeat(2,1fr)"} mt="2rem">
      <GridItem colSpan={{ base: 1, lg: 1, sm: 2 }}>
        <Flex gap="1rem" mb="1rem">
          {" "}
          <LeftSideLabelInput
            label={"Total Minutes"}
            onChange={(totalMinute) => setTotalMinute(totalMinute)}
            value={totalMinute}
            type={"number"}
          />
          <Input
            fontSize="sm"
            type={"number"}
            placeholder="Per Minute Price"
            onChange={(e) => setPerMinutePrice(e.target.value)}
            value={perMinutePrice}
          />
        </Flex>
        {passenger?.map((pass, index) => {
          return (
            <Grid key={index} templateColumns={"repeat(3,1fr)"} mb="1rem">
              <GridItem colSpan={3}>
                <Flex gap="1.2rem" alignItems="center">
                  <Box w="7rem" fontSize={"sm"}>
                    Passenger Name
                  </Box>
                  <Box>
                    <Input
                      name={`passengername.${index}`}
                      id={`passengername.${index}`}
                      type={"text"}
                      w="15rem"
                      onChange={(e) => updateList(e.target.value, index)}
                      value={pass.name}
                    />
                  </Box>
                  <GridItem key={index}>
                    <Box pos="relative">
                      {pass?.image && (
                        <Image
                          // src={pass?.image}
                          src={
                            filterImage[index]?.index === index // this logic is used for showing the image when new passenger field is added
                              ? filterImage[index]?.image
                              : URL.createObjectURL(pass?.image[0])
                          }
                          objectFit={"cover"}
                          height={"3rem"}
                          width={"3rem"}
                          borderRadius={"5px"}
                          border={"1px solid orange"}
                          // onClick={() => {
                          //   handleSingleImageClick();
                          //   onUpdateSingleImage(item.uuid);
                          // }}
                        />
                      )}
                    </Box>
                  </GridItem>

                  <FormLabel
                    border={"1px solid #C0C0C0"}
                    p="6px"
                    borderRadius={"5px"}
                    // w="7rem"
                    fontSize="sm"
                    onClick={() => {
                      console.log("index", index);
                      // hiddenFileInput.current.click();
                    }}
                  >
                    {pass.image ? "Edit" : " Upload Image"}

                    <input
                      id="image"
                      // name={`passengername.${index}`}
                      // id={`passengername.${index}`}
                      type="file"
                      hidden
                      multiple
                      ref={hiddenFileInput}
                      onChange={(e) => {
                        handleUpload(e, index);
                        uuidOfImage.current = pass?.uuid;
                      }}
                    />
                  </FormLabel>
                </Flex>
              </GridItem>
            </Grid>
          );
        })}

        <Box
          color="orange.700"
          ml={"8rem"}
          mt={"1rem"}
          mb={"1rem"}
          cursor={"pointer"}
          onClick={() =>
            setPassenger((oldArray) => [...oldArray, { name: "" }])
          }
        >
          Add Another Passenger
        </Box>
      </GridItem>

      <GridItem colSpan={{ base: 1, lg: 1, sm: 2 }} ml={{ lg: "1rem" }}>
        <UploadBulkImagePassenger
          isMultiple={true}
          onImageSelected={setPassengerImage}
          setFile={setPassengerFile}
          file={passengerFile}
          infoForEdit={infoForEdit}
          editReports={editReports}
          onDelete={deletePassengerImage}
          onUpdateSingleImage={updateSingleImage}
          setSingleImage={setSingleImage}
        />
      </GridItem>
    </Grid>
  );
};
