import React from "react";
import { Button } from "@chakra-ui/react";

export const SubmitButton = ({ onClick }) => {
  return (
    <Button
      type="submit"
      bg="#f77f3c"
      color="white"
      mt="4rem"
      w="7rem"
      size="sm"
      onClick={onClick}
    >
      Save
    </Button>
  );
};
