// Chakra imports
import {
  Flex,
  Grid,
  GridItem,
  Box,
  Button,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card.js";
import { StatusUI } from "components/Common/StatusUI";
import React from "react";
import { useState } from "react";
import Moment from "react-moment";
import { useGetTransactionList } from "./API/useGetTransactionList";
import { ReportSorting } from "./Components/ReportSorting";
import { CgChevronLeft, CgChevronRight } from "react-icons/cg";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { infoForEditFlight } from "./slice/editFlightSlice";
import Invoice from "./Components/Invoice";

function Reports() {
  // Chakra color mode
  const [statusUuid, setUuid] = useState(); // used for sorting of transaction list , which is sent to api
  const [pageNumber, setPageNumber] = useState(1);
  const [editReports, setEditReports] = useState();
  const history = useHistory();
  const dispatch = useDispatch();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    dataGetTransactionList,
    transactionListIsLoading,
  } = useGetTransactionList(statusUuid, pageNumber);

  function handelOnNext() {
    if (dataGetTransactionList?.data.has_next) {
      setPageNumber(pageNumber + 1);
    }
  }

  function handelOnPrevious() {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  }

  function handelView(info) {
    history.push("/admin/viewRecord");
    dispatch(infoForEditFlight(info));
  }

  function handelInvoice(info) {
    dispatch(infoForEditFlight(info));
    onOpen();
  }
  return (
    <Flex direction="column">
      <Card h="97vh">
        <Invoice isOpen={isOpen} onClose={onClose} />
        <Box mt="7rem">
          <Grid templateColumns="repeat(8, 1fr)" columnGap={{ sm: 5, md: 12 }}>
            <GridItem colSpan={{ base: 1, md: 1, sm: 4 }}>
              <StatusUI
                title="Total Trips"
                number={dataGetTransactionList?.data?.statistics.total_trip}
              />
            </GridItem>
            <GridItem colSpan={{ base: 1, md: 1, sm: 4 }}>
              <StatusUI
                title="Pending Approval"
                number={
                  dataGetTransactionList?.data?.statistics.pending_transactions
                }
                color="red.400"
              />
            </GridItem>
            <GridItem colSpan={{ base: 1, md: 1, sm: 4 }}>
              <StatusUI
                title="Approval Trip"
                number={
                  dataGetTransactionList?.data?.statistics.approved_transactions
                }
                color="green.400"
              />
            </GridItem>
            <GridItem colSpan={{ base: 1, md: 1, sm: 4 }}>
              <StatusUI
                title="Total Transcation"
                number={
                  dataGetTransactionList?.data?.statistics.total_transaction ===
                  undefined
                    ? 0
                    : dataGetTransactionList?.data?.statistics.total_transaction
                }
              />
            </GridItem>
            <GridItem colStart={{ sm: 8, md: 6 }} mt={{ sm: "1rem" }}>
              <ReportSorting setUuid={setUuid} />
            </GridItem>
          </Grid>
          <Grid
            fontSize={"1rem"}
            templateColumns="repeat(10, 1fr)"
            gap={1}
            borderTop={"1px solid lightgray"}
            borderBottom={"1px solid lightgray"}
            p={2}
            mt={"1.5rem"}
            mb={2}
          >
            <GridItem colSpan={2}>DATE</GridItem>
            <GridItem colSpan={2}>INVOICE</GridItem>
            <GridItem colSpan={2}>FLIGHT</GridItem>
            <GridItem colSpan={2}>AMOUNT</GridItem>
            <GridItem colSpan={2}>STATUS</GridItem>
          </Grid>
          <Spinner
            pos={"absolute"}
            color="orange"
            zIndex={100}
            top="50%"
            left={"50%"}
            size={"lg"}
            display={transactionListIsLoading ? "block" : "none"}
          />
          {dataGetTransactionList?.data?.flight.length === 0 && (
            <Flex color="gray.500" justifyContent={"center"} mt="1rem">
              No any requested data
            </Flex>
          )}
          {dataGetTransactionList?.data?.flight.map((list) => {
            return (
              <Grid
                boxShadow="sm"
                p={2}
                borderRadius={"10px"}
                fontSize={"sm"}
                templateColumns={{
                  sm: "repeat(11, 1fr)",
                  md: "repeat(10, 1fr)",
                }}
                gap={1}
                mb={2}
                _hover={{ bg: "#f6f6f6" }}
                key={list.uuid}
                cursor={"pointer"}
              >
                <GridItem
                  alignSelf={"center"}
                  colSpan={{ sm: 3, md: 2 }}
                  onClick={() => handelView(list)}
                >
                  <Moment format="YYYY/MM/DD">{list.created_at}</Moment>
                </GridItem>
                <GridItem
                  colSpan={2}
                  alignSelf={"center"}
                  color="orange.700"
                  onClick={() => handelInvoice(list)}
                >
                  #{list.invoice.invoice_number}
                </GridItem>
                <GridItem
                  alignSelf={"center"}
                  colSpan={2}
                  onClick={() => handelView(list)}
                >
                  {list.flight_number === "null" ? "N/A" : list.flight_number}
                </GridItem>
                <GridItem
                  alignSelf={"center"}
                  colSpan={2}
                  onClick={() => handelView(list)}
                >
                  ${list.invoice.invoice_amount}
                </GridItem>
                <GridItem
                  colSpan={2}
                  alignSelf={"center"}
                  textTransform="uppercase"
                  color={
                    list.status === "Pending"
                      ? "yellow.500"
                      : list.status === "Conflict"
                      ? "red"
                      : "green"
                  }
                  onClick={() => handelView(list)}
                >
                  {list.status}{" "}
                </GridItem>
              </Grid>
            );
          })}
          <Flex mt="2rem" gap="1rem" justify={"center"}>
            <Button
              bg="gray.300"
              color="black"
              p={2}
              justifyItems="center"
              alignItems={"center"}
              borderRadius="5px"
              onClick={handelOnPrevious}
              disabled={pageNumber === 1 ? "true" : false}
              cursor="pointer"
            >
              <CgChevronLeft />
            </Button>

            <Button
              bg="gray.300"
              color="black"
              p={2}
              justifyItems="center"
              alignItems={"center"}
              borderRadius="5px"
              onClick={handelOnNext}
              disabled={dataGetTransactionList?.data.has_next ? false : true}
            >
              <CgChevronRight />
              {/* Or an icon from `react-icons` */}
            </Button>
          </Flex>
        </Box>
      </Card>
    </Flex>
  );
}

export default Reports;
