import React from "react";
import { Button } from "@chakra-ui/react";

export const SaveButton = ({onClick}) => {
  return (
    <Button bg="#f77f3c" color="white" mt="4rem" w="7rem" size="sm" onClick={onClick}>
      Save
    </Button>
  );
};
