import { configureStore } from "@reduxjs/toolkit";
import { recordFlightReducer } from "views/Pages/Record Flights/slice/recordFlightSlice";
import { editFlightReducer } from "views/Pages/Reports/slice/editFlightSlice";
import { signInReducer } from "views/Pages/signin/slice/signInSlice";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
const persistConfigLogin = {
  key: "root",
  storage,
  whitelist: ["signIN"],
};

const persistConfigReportOfEdit = {
  key: "root2",
  storage,
  // whitelist: ["infoForEditFlight"],
};

const persistedSignIn = persistReducer(persistConfigLogin, signInReducer);
const persistReportInfoForEdit = persistReducer(
  persistConfigReportOfEdit,
  editFlightReducer
);

export default configureStore({
  reducer: {
    signIn: persistedSignIn,
    recordFlight: recordFlightReducer,
    infoForEditFlight: persistReportInfoForEdit,
  },
  // devTools: false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
