import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery, useMutation } from "react-query";

export const useUpdateSingleImagePassenger = () => {
  async function putSingleImagePassenger(values) {
    const res = await authorizationAxios.put(
      `${BASE_URL}/api/heli-partners/passengers/`,
      values
    );
    return res;
  }

  const putSingleImagePassengersMutation = useMutation(putSingleImagePassenger);

  return {
    putSingleImagePassengersMutation,
  };
};
