import {
  Box,
  Flex,
  Grid,
  GridItem,
  Input,
  Select,
  Center,
  Image,
} from "@chakra-ui/react";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { LeftSideLabelSelect } from "components/Common/LeftSideLabelSelect";
import { UploadUI } from "components/Common/UploadUI";
import React from "react";
import { useState } from "react";
import { useCurrencyType } from "../API/useCurrencyType";
import { IoCloseCircleOutline, IoCloudUploadOutline } from "react-icons/io5";

export const PaymentDetails = ({
  onChange,
  setInvoiceImage,
  value,
  setPaymentFile,
  paymentFile,
  currencyUnit,
  setCurrencyUnit,
  formik,
  editReports,
  infoForEdit,
}) => {
  let arrayTest2 = [];
  const [arrayTest, setArrayTest] = useState(
    editReports ? infoForEdit?.invoice_extra : [1]
  );
  const { currencyTypeData } = useCurrencyType();

  const removeAmountField = (index) => {
    setArrayTest(arrayTest.filter((item, i) => i !== index));
    formik.values.paymentAmount = formik.values.paymentAmount.filter(
      (item, i) => i !== index
    );
    formik.values.paymentName = formik.values.paymentName.filter(
      (item, i) => i !== index
    );
  };
  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  function handleUpload(event) {
    setPaymentFile(Array.from(event.target.files));
    setInvoiceImage(Array.from(event.target.files));
  }
  // console.log("paymentAmount", formik.values.paymentAmount);
  return (
    <Grid templateColumns={"repeat(3,1fr)"} mt="1.2rem" gap={4}>
      <GridItem colSpan={{ base: 1, lg: 1, md: 3, sm: 3 }}>
        <Grid templateRows={"1fr ,1fr"} rowGap="1rem">
          <GridItem>
            {/* <Flex gap="1.2rem">
              <Box w="7rem" fontSize={"sm"}>
                Amount
              </Box>
              <Box>Dollar($)</Box>
            </Flex> */}

            {currencyTypeData && (
              <LeftSideLabelSelect
                label="Currency"
                data={currencyTypeData.data}
                onSelect={setCurrencyUnit}
                value={currencyUnit}
                forPaymentDetails={true}
              />
            )}
          </GridItem>
          {/* <GridItem>
            <LeftSideLabelInput
              label="Amount"
              onChange={onChange}
              type={"number"}
              value={value}
            />
          </GridItem> */}
          {arrayTest?.map((data, index) => {
            return (
              <GridItem key={index}>
                <Flex gap="1.2rem" alignItems="center">
                  <Box w="7rem" fontSize={"sm"}>
                    <Input
                      // placeholder="Select Option"
                      size={"sm"}
                      defaultValue={"Flight Fair"}
                      name={`paymentName.${index}`}
                      id={`paymentName.${index}`}
                      onChange={formik.handleChange}
                      value={
                        formik.values.paymentName === undefined
                          ? "Flight Fair"
                          : formik.values.paymentName[index]
                      }
                      // defaultValue={
                      //   formik.values.paymentName === undefined
                      //     ? ""
                      //     : (formik.values.paymentName[index] = "flightFair")
                      // }
                    />
                      {/* <option
                        value="flightFair"
                        selected={data.name === "flightFair" ? "true" : ""}
                      >
                        Flight fair
                      </option>
                      <option
                        value="permit"
                        selected={data.name === "permit" ? "true" : ""}
                      >
                        Permit
                      </option>
                      <option
                        value="sticker"
                        selected={data.name === "sticker" ? "true" : ""}
                      >
                        Sticker
                      </option> */}
                    {/* </Select> */}
                  </Box>
                  <Box>
                    {" "}
                    <Input
                      type="number"
                      w={{ base: "15rem", md: "15rem", sm: "10rem" }}
                      name={`paymentAmount.${index}`}
                      id={`paymentAmount.${index}`}
                      onChange={formik.handleChange}
                      value={
                        formik.values.paymentAmount === undefined
                          ? ""
                          : formik.values.paymentAmount[index]
                      }
                      // onChange={(e) => onChange(e.target.value)}
                      // value={value}
                    />
                  </Box>
                  {index > 0 && (
                    <IoCloseCircleOutline
                      color={"red"}
                      fontSize={"1rem"}
                      style={{ cursor: "pointer" }}
                      onClick={() => removeAmountField(index)}
                    />
                  )}
                </Flex>
              </GridItem>
            );
          })}

          <Flex>
            <Box w="35%"></Box>
            <Box
              color="#f77f3c"
              cursor={"pointer"}
              onClick={() => {
                arrayTest2.push(arrayTest + 1);
                formik.values.paymentName.push("Flight Fair");
                setArrayTest((oldArray) => [...oldArray, arrayTest2]);
              }}
            >
              Add Item
            </Box>
            {console.log("values", formik.values.paymentAmount)}
            {console.log("values payment", formik.values.paymentName)}
          </Flex>
        </Grid>
      </GridItem>
      <GridItem colSpan={{ base: 2, lg: 2, md: 3, sm: 3 }} ml={{ lg: "8rem" }}>
        <>
          <input
            type="file"
            hidden
            ref={hiddenFileInput}
            onChange={handleUpload}
          />
          <Grid
            templateColumns={"repeat(4,1fr)"}
            cursor="pointer"
            onClick={handleClick}
            gap={4}
          >
            <GridItem colSpan={{ base: 1, md: 1, sm: 3 }}>
              <Center
                w="12rem"
                h="8rem"
                borderTop={"1px dashed #808080"}
                borderLeft={"1px dashed #808080"}
                borderRight={"1px dashed #808080"}
                borderTopRadius="8px"
              >
                <IoCloudUploadOutline fontSize={"3rem"} color="lightgrey" />
              </Center>
              <Center
                w="12rem"
                h="2rem"
                border={"1px solid #808080"}
                borderBottomRadius="8px"
              >
                Upload Image
              </Center>
            </GridItem>
            {paymentFile
              ? paymentFile.map((item, index) => (
                  <GridItem key={index} colSpan={{ base: 1, md: 1, sm: 2 }}>
                    <Image
                      src={URL.createObjectURL(item)}
                      objectFit={"cover"}
                      height={"10rem"}
                      width={"12rem"}
                      borderRadius={"5px"}
                      border={"1px solid orange"}
                    />
                  </GridItem>
                ))
              : ""}
            {editReports && paymentFile.length === 0 ? (
              <GridItem colSpan={{ base: 1, md: 1, sm: 2 }}>
                <Image
                  src={infoForEdit?.invoice_image}
                  objectFit={"cover"}
                  height={"10rem"}
                  width={"12rem"}
                  borderRadius={"5px"}
                  border={"1px solid orange"}
                />
              </GridItem>
            ) : (
              ""
            )}
          </Grid>
        </>
      </GridItem>
    </Grid>
  );
};
