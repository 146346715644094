import axios from "axios";
import { BASE_URL } from "../constants/BASE_URL";

export const authorizationAxios = axios.create({
  BASE_URL,
  // baseURL: "http://192.168.1.71:5000/",
});

authorizationAxios.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("access_token")) {
      config.headers["Authorization"] = `Bearer ${localStorage.getItem(
        "access_token"
      )}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
