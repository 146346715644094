import React from "react";
import {
  Box,
  Flex,
  Grid,
  GridItem,
  HStack,
  Input,
  Select,
  useToast,
} from "@chakra-ui/react";
import { LeftSideLabelSelect } from "components/Common/LeftSideLabelSelect";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { UploadUI } from "components/Common/UploadUI";
import { useDeleteFlightImage } from "../API/useDeleteFlightImage";
import { useUpdateSingleImageCargoDetails } from "../API/useUpdateSingleImageCargoDetails";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useGetSpecificTransactionList } from "../API/useGetSpecificTransactionList";
import { useDispatch } from "react-redux";
import { getSpecificTransactionList } from "views/Pages/Reports/slice/editFlightSlice";

export const CargoDetails = ({
  file,
  setFile,
  value,
  openModalWithSpecifiedWeight,
  unit,
  weightUnit,
  editReports,
  setValue,
  infoForEdit,
  setCargoImage,
  perKgPrice,
  setPerKgPrice,
  totalMinute,
  setTotalMinute,
  perMinutePrice,
  setPerMinutePrice,
}) => {
  const { deleteFlightImageMutation } = useDeleteFlightImage();
  const {
    isSuccess: isDeleteIsSucess,
    isLoading: isDeleteIsLoading,
  } = deleteFlightImageMutation;
  let uuid = useRef(null);
  const { dataGetSpecificTransactionList } = useGetSpecificTransactionList(
    uuid.current
  );
  const {
    putSingleImageCargoDetailsMutation,
  } = useUpdateSingleImageCargoDetails();
  const {
    isSuccess: isUpdateImageSucess,
    isLoading: isUpdateImageLoading,
  } = putSingleImageCargoDetailsMutation;
  const {
    isSuccess: singleImageCargoDetailsIsSucess,
  } = putSingleImageCargoDetailsMutation;
  function deleteCargoImage(uuid) {
    // useDeleteFlightImage(uuid);
    deleteFlightImageMutation.mutate(uuid);
  }
  const [singleImage, setSingleImage] = useState();
  const dispatch = useDispatch();
  const toast = useToast();
  let uuidOfImage = useRef();
  function updateSingleImage(uuid) {
    uuidOfImage.current = uuid;
  }

  useEffect(() => {
    if (isDeleteIsSucess) {
      dispatch(getSpecificTransactionList(infoForEdit?.uuid));
      toast({
        title: "Deleted Successfully!",
        position: "top-right",
        status: "success",
        duration: 1000,
        isClosable: true,
      });
    }

    if (isUpdateImageSucess) {
      dispatch(getSpecificTransactionList(infoForEdit?.uuid));
      toast({
        title: "Updated Successfully!",
        position: "top-right",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }

    if (isUpdateImageLoading) {
      dispatch(getSpecificTransactionList(infoForEdit?.uuid));
      toast({
        title: "updating image...",
        position: "top-right",
        status: "info",
        duration: 1000,
        isClosable: true,
      });
    }

    if (isDeleteIsLoading) {
      toast({
        title: "deleting...",
        position: "top-right",
        status: "info",
        duration: 1000,
        isClosable: true,
      });
    }
  }, [
    isDeleteIsSucess,
    isDeleteIsLoading,
    isUpdateImageSucess,
    isUpdateImageLoading,
  ]);

  useEffect(() => {
    if (singleImage) {
      const fromdata = new FormData();
      fromdata.append("image", singleImage[0]);
      fromdata.append("uuid", uuidOfImage.current);
      putSingleImageCargoDetailsMutation.mutate(fromdata);
    }
  }, [singleImage]);
  if (singleImageCargoDetailsIsSucess) {
    uuid.current = infoForEdit.uuid;
  }

  return (
    <Grid templateColumns={"repeat(4,1fr)"} mt="1.2rem" gap={5}>
      <GridItem colSpan={{ base: 1, lg: 2, md: 3, sm: 3 }}>
        <Grid templateRows={"3fr ,1fr"} rowGap="1rem">
          <GridItem colSpan={3}>
            <Flex gap="1rem">
              {" "}
              <LeftSideLabelInput
                label={"Total Minutes"}
                onChange={(totalMinute) => setTotalMinute(totalMinute)}
                value={totalMinute}
              />
              <Input
                fontSize="sm"
                type={"text"}
                placeholder="Per Minute Price"
                onChange={(e) => setPerMinutePrice(e.target.value)}
                value={perMinutePrice}
              />
            </Flex>
          </GridItem>
          <GridItem colSpan={3}>
            <Flex gap="1rem">
              <LeftSideLabelInput
                label={"Total Kgs"}
                onChange={(value) => setValue(value)}
                value={value}
                type={"number"}
              />
              <Input
                fontSize="sm"
                type={"number"}
                placeholder="Per Kg Price"
                onChange={(e) => setPerKgPrice(e.target.value)}
                value={perKgPrice}
              />
            </Flex>
          </GridItem>
          <GridItem ml={"8rem"} colSpan={3}>
            <Box
              color="orange.700"
              cursor={"pointer"}
              onClick={openModalWithSpecifiedWeight}
            >
              Add Specified KG per Location
            </Box>
          </GridItem>
        </Grid>
      </GridItem>
      <GridItem colStart={3} colSpan={4} ml="3rem">
        <UploadUI
          isMultiple={true}
          onImageSelected={(file) => setCargoImage(file)}
          file={file}
          setFile={setFile}
          editReports={editReports}
          fileForEdit={infoForEdit?.images}
          onDelete={deleteCargoImage}
          onUpdateSingleImage={updateSingleImage}
          setSingleImage={setSingleImage}
        />
      </GridItem>
    </Grid>
  );
};
