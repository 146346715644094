import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery, useMutation } from "react-query";

export const usePostAddPassenger = () => {
  async function postAddPassenger(values) {
    const res = await authorizationAxios.post(
      `${BASE_URL}/api/heli-partners/passengers/`,
      values
    );
    return res;
  }

  const postAddPassengerMutation = useMutation(postAddPassenger);

  return {
    postAddPassengerMutation,
  };
};
