import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery, useMutation } from "react-query";

export const usePostTripLocation = () => {
  async function postTripLocation(values) {
    const res = await authorizationAxios.post(
      `${BASE_URL}/api/heli-partners/flight-location/`,
      values
    );
    return res;
  }

  const postTripLocationMutation = useMutation(postTripLocation);

  return {
    postTripLocationMutation,
  };
};
