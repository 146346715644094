import { authorizationAxios } from "axios/authorizationAxios";
import { BASE_URL } from "constants/BASE_URL";
import { useQuery, useMutation } from "react-query";

export const useUpdateSingleImageCargoDetails = () => {
  async function putSingleImageCargoDetails(values) {
    const res = await authorizationAxios.put(
      `${BASE_URL}/api/heli-partners/flight-images/ `,
      values
    );
    return res;
  }

  const putSingleImageCargoDetailsMutation = useMutation(
    putSingleImageCargoDetails
  );

  return {
    putSingleImageCargoDetailsMutation,
  };
};
