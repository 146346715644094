import { Box, Center, Flex, Grid, GridItem, Image } from "@chakra-ui/react";
import React from "react";
import { IoCloudUploadOutline, IoCloseCircleOutline } from "react-icons/io5";
import { FiEdit2 } from "react-icons/fi";

export const UploadUI = ({
  isMultiple,
  onImageSelected,
  file,
  setFile,
  editReports,
  fileForEdit,
  onDelete,
  onUpdateSingleImage,
  setSingleImage,
}) => {
  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);
  const fileInputForSingleImage = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleSingleImageClick = (event) => {
    fileInputForSingleImage.current.click();
  };

  function handleUpload(event) {
    setFile(Array.from(event.target.files));
    // setSingleImage(Array.from(event.target.files));
    onImageSelected(Array.from(event.target.files));
    console.log("file", event.target.files);
  }

  function handleSingleUpload(event) {
    setSingleImage(Array.from(event.target.files));
  }

  return (
    <>
      <input
        type="file"
        multiple={isMultiple}
        hidden
        ref={hiddenFileInput}
        onChange={handleUpload}
      />
      <input
        type="file"
        multiple={isMultiple}
        hidden
        ref={fileInputForSingleImage}
        onChange={handleSingleUpload}
      />
      <Grid templateColumns={"repeat(3,1fr)"} cursor="pointer" gap={4}>
        <GridItem colSpan={{ base: 1, md: 1, sm: 3 }} onClick={handleClick}>
          <Center
            w="12rem"
            h="8rem"
            borderTop={"1px dashed #808080"}
            borderLeft={"1px dashed #808080"}
            borderRight={"1px dashed #808080"}
            borderTopRadius="8px"
          >
            <IoCloudUploadOutline fontSize={"3rem"} color="lightgrey" />
          </Center>
          <Center
            w="12rem"
            h="2rem"
            border={"1px solid #808080"}
            borderBottomRadius="8px"
          >
            Upload Image
          </Center>
        </GridItem>
        {file
          ? file.map((item, index) => (
              <GridItem key={index} colSpan={{ base: 1, md: 1, sm: 2 }}>
                <Image
                  src={URL.createObjectURL(item)}
                  objectFit={"cover"}
                  height={"10rem"}
                  width={"12rem"}
                  borderRadius={"5px"}
                  border={"1px solid orange"}
                  onClick={handleClick}
                />
              </GridItem>
            ))
          : ""}
        {editReports
          ? fileForEdit?.map((item, index) => (
              <GridItem key={index}>
                <Box pos="relative">
                  <Flex
                    pos="absolute"
                    w="100%"
                    justifyContent={"space-between"}
                    p="4px"
                    gap="6px"
                  >
                    <FiEdit2
                      color={"#525252"}
                      fontSize={"1rem"}
                      style={{ cursor: "pointer" }}
                    />
                    <IoCloseCircleOutline
                      color={"red"}
                      fontSize={"1rem"}
                      style={{ cursor: "pointer" }}
                      onClick={() => onDelete(item.uuid)}
                    />
                  </Flex>
                  <Image
                    src={item?.image}
                    objectFit={"cover"}
                    height={"10rem"}
                    width={"12rem"}
                    borderRadius={"5px"}
                    border={"1px solid orange"}
                    onClick={() => {
                      handleSingleImageClick();
                      onUpdateSingleImage(item.uuid);
                    }}
                  />
                </Box>
              </GridItem>
            ))
          : ""}
      </Grid>
    </>
  );
};
