import React, { useRef } from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useColorMode,
  Modal,
  ModalContent,
  ModalOverlay,
  ModalBody,
  ModalHeader,
  ModalCloseButton,
  HStack,
  Grid,
  GridItem,
} from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
// import { ArgonLogoLight } from "components/Icons/Icons";
import { ArgonLogoLight, ArgonLogoDark } from "components/Icons/Icons";
import { useSelector } from "react-redux";
import { IoArrowForward } from "react-icons/io5";
import { useReactToPrint } from "react-to-print";

class ComponentToPrint extends React.Component {
  // for react-to-print to work, it must be called from a class based component
  render() {
    const {
      textColor,
      secondaryColor,
      borderColor,
      colorMode,
      isOpen,
      onClose,
      infoForInvoice,
    } = this.props;
    return (
      <Card
        w={{ sm: "330px", md: "500px", lg: "900px" }}
        justifySelf="center"
        alignSelf="center"
        mt="50px"
        // p={{ sm: "24px", md: "44px" }}
        p="2rem"
      >
        <ModalCloseButton />
        <CardHeader mb={{ sm: "60px", md: "95px" }}>
          <Flex direction="column" w="100%">
            <Image
              src={infoForInvoice?.airlines.logo}
              w="250px"
              // h="112px"
              ml="-2rem"
              objectFit={"contain"}
            />

            <Flex
              direction={{ sm: "column", md: "row" }}
              justify="space-between"
              w="100%"
              // mt="-1.5rem"
            >
              <Flex
                direction="column"
                maxW={{ sm: "100%", md: "150px", lg: "300px" }}
                // mb={{ sm: "48px", md: "0px" }}
              >
                <Text
                  fontWeight="normal"
                  fontSize="md"
                  // mb="12px"
                >
                  {infoForInvoice?.airlines.name}
                </Text>

                <Text fontWeight="normal" fontSize="md">
                  {infoForInvoice?.airlines.address}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>
        <CardBody>
          <Flex direction="column" w="100%">
            <Flex
              direction={{ sm: "column", md: "row" }}
              justify="space-between"
              w="100%"
              mb="60px"
            >
              <Flex direction="column" mb={{ sm: "16px", md: "0px" }}>
                <Text fontWeight="normal" fontSize="md">
                  Bill To:
                </Text>
                <Text fontWeight="normal" fontSize="md">
                  Elite Himalayan Adventure Pvt.Ltd.
                  <br /> Kathmandu,Nepal
                  <br />
                  Pan No:609644724
                </Text>
              </Flex>
              <Flex direction="column">
                <Text fontWeight="normal" fontSize="md">
                  Invoice No: {infoForInvoice?.invoice.invoice_number}
                </Text>

                <Text fontWeight="normal" fontSize="md">
                  Due date: {infoForInvoice?.invoice.due_date}
                </Text>
              </Flex>
            </Flex>
            <Box overflowX={{ sm: "scroll", lg: "hidden" }}>
              <Table
                mb="85px"
                overflowX={{ sm: "scroll", lg: "hidden" }}
                border="0.5px solid black"
                variant={"unstyled"}
                size="sm"
              >
                <Thead border="0.5px solid black">
                  <Tr>
                    <Th>
                      <Text
                        fontWeight="medium"
                        fontSize="sm"
                        textTransform={"none"}
                      >
                        Flight Date:
                      </Text>
                    </Th>
                    <Th
                      fontWeight="medium"
                      fontSize="sm"
                      textTransform={"none"}
                      borderLeft={"0.5px solid black"}
                    >
                      Particular
                    </Th>

                    <Th
                      fontWeight="medium"
                      fontSize="sm"
                      borderLeft={"0.5px solid black"}
                    >
                      USD
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>{infoForInvoice.location[0].date.split("T")[0]}</Td>
                    <Td
                      borderColor={borderColor}
                      borderLeft={"0.5px solid black"}
                    >
                      {" "}
                      <Text
                        // color={secondaryColor}
                        fontWeight="bold"
                        fontSize="md"
                        mb="0.3rem"
                      >
                        <u>{infoForInvoice?.type_of_flight?.name}</u>
                      </Text>
                      <HStack>
                        {infoForInvoice?.location?.map((loc, index) => (
                          <Box
                            fontSize={"md"}
                            key={index}
                            display={"contents"}
                            // color={secondaryColor}
                          >
                            {loc.name}&nbsp;
                            {infoForInvoice.location.length > index + 1 ? (
                              <Box>-></Box>
                            ) : (
                              ""
                            )}
                            &nbsp;
                          </Box>
                        ))}
                      </HStack>
                    </Td>
                    <Td borderLeft={"0.5px solid black"}></Td>
                  </Tr>
                  <Tr>
                    <Td borderLeft={"0.5px solid black"}></Td>
                    <Td borderLeft={"0.5px solid black"}>
                      {" "}
                      <Box fontSize={"md"}>
                        {" "}
                        <Text mb="0.3rem">
                          {infoForInvoice?.total_minutes}mins @$
                          {infoForInvoice?.minute_price}/min= $
                          {infoForInvoice?.total_minutes *
                            infoForInvoice?.minute_price}
                        </Text>
                        {infoForInvoice?.passenger.length >= 1 ? (
                          infoForInvoice?.passenger.map((list) => {
                            return <Text>{list.name}</Text>;
                          })
                        ) : (
                          <Text>
                            {infoForInvoice?.total_kgs}kg @$
                            {infoForInvoice?.kg_price}/kg= $
                            {infoForInvoice?.total_kgs *
                              infoForInvoice?.kg_price}
                          </Text>
                        )}
                      </Box>
                    </Td>
                    <Td borderLeft={"0.5px solid black"}></Td>
                  </Tr>

                  <Tr></Tr>

                  {infoForInvoice?.invoice_extra.map((invoice) => {
                    return (
                      <Tr>
                        <Td borderLeft={"0.5px solid black"}></Td>
                        <Td borderLeft={"0.5px solid black"}>
                          <Text fontWeight="normal" fontSize="md">
                            {invoice.name}
                          </Text>
                        </Td>
                        <Td borderLeft={"0.5px solid black"}>
                          {" "}
                          <Text fontWeight="normal" fontSize="md">
                            {invoice.amount}
                          </Text>
                        </Td>
                      </Tr>
                    );
                  })}

                  <Tr border="0.5px solid black">
                    <Td></Td>
                    <Td fontSize="md" borderLeft={"0.5px solid black"}>
                      Total
                    </Td>
                    <Td
                      borderColor={borderColor}
                      borderLeft={"0.5px solid black"}
                    >
                      {infoForInvoice?.invoice?.total_amount}
                      <Text
                        color={textColor}
                        fontWeight="bold"
                        fontSize="md"
                      ></Text>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td></Td>
                    <Td fontSize="md" borderLeft={"0.5px solid black"}>
                      Net Receivable
                    </Td>
                    <Td fontSize="md" borderLeft={"0.5px solid black"}>
                      {infoForInvoice?.invoice?.total_amount}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
            <Flex
              direction={{ sm: "column", md: "row" }}
              justify="space-between"
            >
              <Flex direction="column" maxW="270px">
                <Text fontWeight="bold" fontSize="md">
                  Thank You!
                </Text>
                <Text fontWeight="normal" fontSize="md" mt="6px" mb="30px">
                  Great doing business with you.
                </Text>
              </Flex>
              <Button
                onClick={() => this.props.handlePrint()}
                variant="primary"
                w="100px"
                h="35px"
                alignSelf={{ sm: "flex-start", md: "flex-end" }}
                mt={{ sm: "16px", md: "0px" }}
              >
                PRINT
              </Button>
            </Flex>
          </Flex>
        </CardBody>
      </Card>
    );
  }
}

function Invoice({ isOpen, onClose }) {
  const textColor = useColorModeValue("gray.700", "white");
  const secondaryColor = useColorModeValue("gray.500", "white");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const { colorMode } = useColorMode();
  const infoForInvoice = useSelector((state) => state.infoForEditFlight.info);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalHeader>Invoice</ModalHeader>

      <ModalOverlay />
      <ModalContent w="0px">
        <ComponentToPrint
          ref={componentRef}
          handlePrint={handlePrint}
          textColor={textColor}
          secondaryColor={secondaryColor}
          borderColor={borderColor}
          colorMode={colorMode}
          // isOpen={isOpen}
          // onClose={onClose}
          infoForInvoice={infoForInvoice}
        />
      </ModalContent>
    </Modal>
  );
}

export default Invoice;
