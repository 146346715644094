import Card from "components/Card/Card";
import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { LeftSideLabelInput } from "components/Common/LeftSideLabelInput";
import { SubmitButton } from "components/Common/SubmitButton";
import { CancelButton } from "components/Common/CancelButton";
import { useState } from "react";
import { usePostLocation } from "../API/usePostLocation";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useGetLocation } from "../API/useGetLocation";

export const AddLocation = ({ isOpen, onClose }) => {
  const { postLocationMutation } = usePostLocation();
  const { refetch } = useGetLocation();
  const {
    isLoading: addLocationIsLoading,
    isError: addLocationIsError,
    error: addLocationError,
    isSuccess: addLocationIsSuccess,
  } = postLocationMutation;
  const [name, setName] = useState("");
  const [shortName, setShortName] = useState("");
  const signInData = useSelector((state) => state.signIn.signIN);
  const toast = useToast();
  function postLocation() {
    postLocationMutation.mutate([
      {
        name,
        shortName,
        description: "description",
        device_id: "web",
        app: "89bda92e-78c7-4ae6-9f80-131b6d0aaa04",
        added_by: signInData.data.uuid,
      },
    ]);
  }

  useEffect(() => {
    if (addLocationIsLoading) {
      toast({
        title: "loading...",
        position: "top-right",
        // description: "You will shortly contact you.",
        status: "info",
        duration: 9000,
        isClosable: true,
      });
    }
    if (addLocationIsError) {
      toast({
        title: addLocationError.message,
        position: "top-right",
        // description: "You will shortly contact you.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }
    if (addLocationIsSuccess) {
      toast({
        title: "Sucessfully added",
        position: "top-right",
        // description: "You will shortly contact you.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      onClose();
      refetch();
    }
  }, [addLocationIsLoading, addLocationIsError, addLocationIsSuccess]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Location</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDirection={"column"} gap="1rem" w="90%">
            <LeftSideLabelInput
              label="Name"
              type="text"
              onChange={(name) => setName(name)}
              value={name}
            />
            <LeftSideLabelInput
              label="Short Name"
              type="text"
              onChange={(shortName) => setShortName(shortName)}
              value={shortName}
            />
          </Flex>
          <Flex gap="1.5rem" pb={"1rem"} mt="-2rem">
            <SubmitButton onClick={postLocation} />
            {/* <button type="submit">Submit</button> */}
            <CancelButton onClose={onClose} />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
